import { useState, useEffect } from 'react'
import { Picture, Text, ButtonV2, Button } from '@components/ui'
import { atobID } from '@lib/utils/tools'
import { Price } from '@components/product'
import { Swiper, SwiperSlide } from 'swiper/react'
import { cn, rmEmptyKeys } from '@components/helper'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { normalizeStorefrontProduct } from '@components/normalize'
import { useRelativeLink } from '@commerce/product/use-relative-link'
import BannerLink from '@components/common/BannerLink'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import isMobile from 'ismobilejs'
import s from './index.module.css'
import ProgressBar from '../ProgressBar'
import useCoupons from '@lib/hooks/useCoupon'
import Discount from '@components/product/Discount'
import useProduct from '@shopify/product/use-product'
import SolixButton from '@components/control/SolixButton'
import { color } from 'framer-motion'

const svgTxt = `<span style="display: inline-block; vertical-align: middle;"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14" fill="none">
<path d="M5.33325 4L8.66659 7.33333L5.33325 10.6667" stroke="#00A9E0" strokeWidth="1" stroke-linecap="round"/>
</svg></span>`

const PrevArrow = ({ onClick, active, c = '', data }) => (
  <button
    onClick={onClick}
    className={cn(
      c,
      s.arrow,
      s.arrowLeft,
      'bestChoice_arrow-left -translate-y-1/2'
    )}
  >
    <Picture
      className="h-[32px] w-[32px]"
      source={
        data?.arrowImg ||
        `https://cdn.shopify.com/s/files/1/0493/9834/9974/files/Kit-Arrows.svg?v=1724056204`
      }
    />
  </button>
)

const NextArrow = ({ onClick, active, c = '', data }) => (
  <button
    onClick={onClick}
    className={cn(
      c,
      s.arrow,
      s.arrowRight,
      'bestChoice_arrow-right -translate-y-1/2 rotate-180'
    )}
  >
    <Picture
      className="h-[32px] w-[32px]"
      source={
        data?.arrowImg ||
        `https://cdn.shopify.com/s/files/1/0493/9834/9974/files/Kit-Arrows.svg?v=1724056204`
      }
    />
  </button>
)

const Index = ({ id, data, pageHandle, locale, abtest }) => {
  const { setRelativeLink } = useRelativeLink()
  const [mobile, setMobile] = useState(false)
  const [swiperRef, setSwiperRef] = useState(null)

  const [current, setCurrent] = useState(0)
  const [realIndex, setRealIndex] = useState(0)

  const [activeProducts, setActiveProducts] = useState(data.list?.[0]?.products)

  const handles = data.list
    ?.reduce(
      (prev, cur) => [
        ...prev,
        ...(cur.products?.map((product) => product.handle) || []),
      ],
      []
    )
    ?.join(',')

  // 三个层级，collection=>products=>bundles
  const skus = data.list?.reduce(
    (prev, cur) => [
      ...prev,
      ...(cur.products?.reduce(
        (prev, product) => [
          ...prev,
          ...(product.bundles?.map((bundle) => bundle.sku) || [product.sku]),
        ],
        []
      ) || []),
    ],
    []
  )

  const { data: result, isLoading } = useProduct({ handles })

  const { coupons } = useCoupons(skus)

  useEffect(() => {
    if (isMobile(window.navigator).phone) {
      setMobile(true)
    } else {
      setMobile(false)
    }
  }, [])

  const settings = {
    speed: 400,
    spaceBetween: 16,
    navigation: {
      nextEl: '.bestChoice_arrow-right',
      prevEl: '.bestChoice_arrow-left',
    },
    loop: data?.loop || false,
    autoplay: data?.autoplay ?? false,
    modules: [Navigation, Autoplay, Pagination],
    pagination: false,
    breakpoints: {
      0: {
        slidesPerView: 1.2,
        spaceBetween: '8px',
      },
      768: {
        slidesPerView: 2.3,
      },
      1023: {
        slidesPerView: 3.3,
      },
    },
    slideToClickedSlide: true,
  }

  const tabs = data.list?.filter((item) => item.label)

  useEffect(() => {
    swiperRef?.slideTo(0)
  }, [current, swiperRef])

  return (
    <section
      id={id}
      className={cn(
        'layer relative overflow-hidden bg-[#F3F4F5] py-[160px] md:py-[80px]',
        {
          ['pt-0 md:pt-0']: data?.delPaddingTop,
          ['pb-0 md:pb-0']: data?.delPaddingBottom,
        }
      )}
      style={rmEmptyKeys({ backgroundColor: data?.bgColor })}
    >
      <div className="content !max-w-[1410px]">
        {data?.title && (
          <Text
            html={data.title}
            className={cn(
              'text-[56px] font-[700] uppercase leading-[1.2] text-[#000] md:text-[32px]',
              {
                '!text-center': abtest,
              }
            )}
          />
        )}

        <div className="mt-[50px] w-full md:mt-[30px] xxl:mt-[20px] relative">
          {tabs?.length > 1 && (
            <div
              className={cn(
                'flex gap-[40px] overflow-x-scroll border-b-[1px] border-[#E1E2E3] md:gap-[20px]'
              )}
            >
              {tabs?.map((tab, index) => {
                return (
                  <button
                    key={tab?.label + index}
                    className={cn(s.tabItem, {
                      [s.activeTab]: index === current,
                    })}
                    onClick={() => {
                      if (index == current) return
                      console.log('tab', tab)
                      if (tab?.type == 'link') {
                        window.location.href = setRelativeLink({
                          link: tab?.href,
                        })
                        return
                      } else {
                        setCurrent(index)
                        const products = data.list?.find(
                          (item) => item.label === tab?.label
                        )?.products
                        setActiveProducts(products)
                      }
                    }}
                  >
                    <span
                      className={cn(
                        'flex h-full w-full items-center justify-center py-[8px] transition-all md:px-[12px] md:py-[4px]'
                      )}
                    >
                      {tab?.label}
                    </span>
                  </button>
                )
              })}
            </div>
          )}
        </div>

        <Swiper
          {...settings}
          onSwiper={setSwiperRef}
          className={cn(s.swiperWrap, {
            [s.swiperWrapLength]: tabs?.length > 1,
          })}
        >
          {activeProducts?.map((item, index) => {
            let product = normalizeStorefrontProduct(item, result, coupons)
            if (!product) return null
            let link = `${locale === 'us' ? '' : '/' + locale}/products/${
              product?.handle
            }?variant=${atobID(product?.variantId)}&ref=${
              data?.ref ? data?.ref + (index + 1) : pageHandle
            }`

            return (
              <SwiperSlide
                className="relative !h-[unset] bg-[#fff]"
                key={index}
              >
                <div className={s.aItem}>
                  <Discount
                    data={data}
                    product={product}
                    save={data?.save || false}
                    className={cn(
                      'top-[20px] h-[30px] w-[109px] text-[#FF7815] md:h-[24px] md:w-[88px]',
                      s.discountWrap
                    )}
                    cns={{
                      discountValue:
                        'text-[16px] font-extrabold md:text-[14px]',
                      discountText: 'text-[16px] font-extrabold md:text-[14px]',
                      discount: 'left-0 flex-row gap-[2px]',
                    }}
                  />
                  <div className="relative mx-auto w-[56%] pt-[50px] md:w-[75%] md:pt-[32px]">
                    <BannerLink to={link} />
                    <Picture
                      className="aspect-h-[250] aspect-w-[250] w-full md:aspect-h-[193] md:aspect-w-[212]"
                      source={`${product?.image}, ${
                        product?.mobileImage || product?.image
                      } 769`}
                    />
                  </div>

                  <div
                    className={cn(s.productBox, 'productBox', {
                      // [s.productBoxAbtest]: abtest,
                    })}
                  >
                    <div>
                      {product?.tag && (
                        <Text html={product.tag} className={s.tag} />
                      )}
                      {product?.title && (
                        <Text
                          html={product.title}
                          className="text-[24px] font-[700] uppercase md:text-[18px]"
                        />
                      )}
                    </div>
                    <div>
                      <Price
                        copy={data}
                        product={product}
                        s={{
                          price:
                            'text-[#000] text-[20px] font-semibold md:text-[16px]',
                          basePrice:
                            'text-[#C3C3C3] line-through md:text-[12px]',
                        }}
                      />
                      <>
                        {link && (
                          <div className={cn(s.buttonWrap, 'buttonWrap')}>
                            <SolixButton
                              squareBorder
                              className={
                                '!max-w-[140px] !leading-[48px] md:!leading-[42px]'
                              }
                            >
                              <a
                                href={link}
                                className="flex items-center justify-center gap-[5px] text-[18px] font-semibold transition  duration-150 ease-in-out"
                              >
                                <Text
                                  html={product?.btnText || data?.btnText}
                                />
                                {/* <ChevronRightIcon className="h-[18px] w-[18px]" /> */}
                              </a>
                            </SolixButton>
                            {product?.solixFlexInfo && (
                              <SolixButton
                                style={{
                                  background: '#fff',
                                  color: '#000',
                                  border: '1px solid #000',
                                }}
                                variant="link"
                                href={link}
                                squareBorder
                                className={
                                  '!max-w-[140px] !leading-[48px] md:!leading-[42px]'
                                }
                              >
                                <div className="flex h-full flex-col items-center justify-center gap-[4px]">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: product?.solixFlexInfo?.btnTxt,
                                    }}
                                    className="text-[16px] font-[700] leading-[16px] text-black"
                                  ></span>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: product?.solixFlexInfo?.mo,
                                    }}
                                    className="text-[14px] font-[500] leading-[14px] text-black"
                                  ></span>
                                </div>
                              </SolixButton>
                            )}
                          </div>
                        )}
                      </>
                      {/* B */}
                      {/* <>
                        {link && (
                          <div
                            className={cn(s.buttonWrap, 'buttonWrap', {
                              [s.buttonWrapAbtest]: abtest,
                            })}
                          >
                            <a
                              href={link}
                              className="flex items-center gap-[5px] text-[18px] font-semibold transition  duration-150 ease-in-out hover:text-[#00A9E0]"
                            >
                              <Text html={product?.btnText || data?.btnText} />
                              <ChevronRightIcon className="h-[18px] w-[18px]" />
                            </a>
                          </div>
                        )}
                      </> */}
                    </div>
                  </div>
                  <ProgressBar
                    s={{ progressBarBox: `${s.progressBarBox} progressBarBox` }}
                  />
                </div>
              </SwiperSlide>
            )
          })}

          {data?.list?.[current]?.viewAllHref && !isLoading && (
            <SwiperSlide
              key={data?.list?.[current]?.viewAllHref + current}
              className="relative !h-[unset] bg-[#fff]"
            >
              <div className="flex h-full w-full items-center justify-center">
                <a
                  href={setRelativeLink({
                    link: `${data?.list?.[current]?.viewAllHref}?ref=${
                      data?.ref || pageHandle
                    }`,
                  })}
                  className="text-[18px] font-[600] leading-[21px] text-[#00A9E0] hover:underline"
                  dangerouslySetInnerHTML={{
                    __html: data?.list?.[current]?.viewAll + svgTxt,
                  }}
                ></a>
              </div>
            </SwiperSlide>
          )}
          <PrevArrow data={data}></PrevArrow>
          <NextArrow data={data}></NextArrow>
        </Swiper>
      </div>

      <style jsx global>{`
        #${id} .${s.swiperWrap} .swiper-pagination {
          bottom: -40px;
          display: flex;
          gap: 5px;
          justify-content: center;
        }

        @media (max-width: 769px) {
          #${id} .${s.swiperWrap} .swiper-pagination {
            bottom: -30px;
          }
        }
        #${id} .${s.swiperWrap} .swiper-button-prev,
        #${id} .${s.swiperWrap} .swiper-button-next {
          width: 18px;
          opacity: 0;
          height: 33px;
          color: #000;
          transition: all 200ms ease-out;
        }

        #${id} .${s.swiperWrap} .swiper-button-prev {
          left: 40px;
        }

        #${id} .${s.swiperWrap} .swiper-button-next {
          right: 40px;
        }

        #${id} .${s.swiperWrap}:hover .swiper-button-prev,
        #${id} .${s.swiperWrap}:hover .swiper-button-next {
          opacity: 1;
        }

        #${id} .${s.swiperWrap} .swiper-button-prev:hover,
        #${id} .${s.swiperWrap} .swiper-button-next:hover {
          color: #000;
        }

        #${id} .${s.swiperWrap} .swiper-button-next:after,
        #${id} .${s.swiperWrap} .swiper-rtl .swiper-button-prev:after,
        #${id} .${s.swiperWrap} .swiper-button-prev:after,
        #${id} .${s.swiperWrap} .swiper-rtl .swiper-button-next:after {
          font-size: 33px;
        }

        @media (max-width: 769px) {
          #${id} .${s.swiperWrap} .swiper-button-next,
          #${id} .${s.swiperWrap} .swiper-button-prev {
            display: none;
          }
        }
      `}</style>
    </section>
  )
}

export default Index
