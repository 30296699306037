import { useState } from 'react'
import { Picture, Text } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import { cn, rmEmptyKeys } from '@components/helper'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './index.module.css'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Index = ({ id, data, pageHandle }) => {
  const settings = {
    speed: 400,
    navigation: false,
    slidesPerView: 1,
    spaceBetween: 12,
    autoplay: data?.autoplay || false,
    modules: [Navigation, Autoplay, Pagination],
    breakpoints: {
      0: {
        slidesPerView: 1.5,
        spaceBetween: 24,
      },
      768: {
        slidesPerView: 3,
      },
    },
    slideToClickedSlide: true,
  }

  return (
    <section
      id={id}
      className={cn(
        'layer relative overflow-hidden bg-[#fff] py-[80px] leading-[1.2] md:py-[65px] md:pr-0',
        {
          ['pt-0 md:pt-0']: data?.delPaddingTop,
          ['pb-0 md:pb-0']: data?.delPaddingBottom,
        }
      )}
      style={rmEmptyKeys({ backgroundColor: data?.bgColor })}
    >
      <div className="content !max-w-[1410px]">
        <Swiper {...settings} className={s.swiperBox}>
          {data?.list?.map((item, index) => (
            <SwiperSlide key={index} className={s.swiperSlide}>
              <Row
                item={item}
                data={data}
                key={index}
                pageHandle={pageHandle}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <style jsx global>
        {`
          ${data?.list?.length < 3 &&
          `#${id} .${s.swiperBox} .swiper-wrapper{
            justify-content: center;
          }
          @media (max-width: 769px) {
            #${id} .${s.swiperBox} .swiper-wrapper {
              justify-content: start;
            }
          }
          `}
        `}
      </style>
    </section>
  )
}

export default Index

const Row = ({ item, data }) => {
  const handleLiveChat = (e) => {
    e.preventDefault()
    const sidebar = document.querySelector('.helpButtonEnabled')
    if (sidebar) {
      sidebar.click()
    }
  }

  return (
    <div className={s.hoverBox}>
      <div className="w-full max-w-[99px] md:max-w-[80px]">
        <Picture
          source={`${item?.img}, ${item?.mobImg || item?.img} 768`}
          className={cn(
            'aspect-h-[99] aspect-w-[99] w-full md:aspect-h-[99] md:aspect-w-[99]'
          )}
        />
      </div>
      <div className="mx-auto max-w-[320px] text-center">
        <Text
          html={item.title}
          className="text-[20px] font-[700] uppercase text-[#000] md:text-[18px]"
        />
        <Text
          html={item.desc}
          className="mt-[5px] text-[16px] font-[500] text-[#6E6E73] md:text-[14px]"
        />
        {item?.link && (
          <a
            href={item?.link}
            onClick={(e) => item?.type == 'liveChat' && handleLiveChat(e)}
            className={s.learnMore}
          >
            <Text html={item?.learnMore} />
            <ChevronRightIcon className="h-[18px] w-[18px]" />
          </a>
        )}
      </div>
    </div>
  )
}
