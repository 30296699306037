import SectionTitle from '../SectionTitle'
import cn from 'classnames'
import s from './index.module.css'
import { useState } from 'react'
import { Picture, Text } from '@components/ui'
import Marquee from '../Marquee'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const CreateStarts = ({ reviewStar = 5 }) => {
  const elements = []

  while (reviewStar >= 0) {
    if (reviewStar > 1 || reviewStar === 0) {
      elements.push(
        <svg
          key={reviewStar}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
        >
          <path
            d="M8 0L9.78687 5.54059L15.6085 5.52786L10.8912 8.93941L12.7023 14.4721L8 11.04L3.29772 14.4721L5.10879 8.93941L0.391548 5.52786L6.21313 5.54059L8 0Z"
            fill="#F68720"
          />
        </svg>
      )
    } else if (reviewStar === 0.5) {
      elements.push(
        <svg
          key={reviewStar}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M8 0.814497L9.54893 5.61732L9.60494 5.79099L9.78741 5.79059L14.8338 5.77956L10.7447 8.73684L10.5969 8.84377L10.6536 9.01719L12.2235 13.8132L8.14739 10.8381L8 10.7305L7.85261 10.8381L3.77647 13.8132L5.34638 9.01719L5.40315 8.84377L5.25529 8.73684L1.16618 5.77956L6.21259 5.79059L6.39506 5.79099L6.45107 5.61732L8 0.814497Z"
            stroke="#F68720"
            strokeWidth="0.5"
          />
          <path
            d="M3.29777 14.4721L8.00005 11.04V0L6.21319 5.54059L0.391602 5.52786L5.10884 8.93941L3.29777 14.4721Z"
            fill="#F68720"
          />
        </svg>
      )
    }
    reviewStar -= 1
  }

  return <>{elements}</>
}

export const Card = ({ item, gs = {} }) => {
  // start_ai_generated
  const [hiddenStyle, setHiddenStyle] = useState('hidden')
  const mouseEnterHandle = () => {
    setHiddenStyle('flex')
  }
  const mouseLeaveHandle = () => {
    setHiddenStyle('hidden')
  }
  // end_ai_generated
  return (
    <div
      onMouseEnter={mouseEnterHandle}
      onMouseLeave={mouseLeaveHandle}
      className={cn(
        'relative mr-[16px] w-[390px] cursor-pointer overflow-hidden rounded-[10px] md:w-[342px] l-xl:w-[330px] md-l:w-[300px]',
        gs?.card
      )}
    >
      <Picture
        className="aspect-h-[390] aspect-w-[390]"
        source={`${item?.img}, ${item?.mbImg || item?.img} 768`}
      ></Picture>
      <div
        className={cn(
          'absolute left-0 top-0 box-border h-full w-full flex-col items-center bg-black/70 px-[50px] pt-[25%] text-center md:px-[20px] md-xl:px-[25px]',
          hiddenStyle
        )}
      >
        {item?.title && (
          <Text
            html={item?.title}
            className="text-[24px] font-semibold leading-[28px] text-white"
          ></Text>
        )}
        {item?.subtitle && (
          <Text
            html={item?.subtitle}
            className="mt-[28px] text-[16px] font-medium leading-[20px] text-white"
          ></Text>
        )}
        {item?.reviewTxt && (
          <div className="absolute bottom-[32px] left-0 flex w-full flex-col items-center text-center">
            <Text
              html={item?.reviewTxt}
              className="text-[12px] font-medium leading-[16px] text-white"
            ></Text>
            {item?.reviewStar && (
              <div className="mt-[8px] flex justify-center gap-[6px]">
                <CreateStarts reviewStar={item?.reviewStar}></CreateStarts>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

function Endorsement({ data, pageHandle, id }) {
  const { setRelativeLink } = useRelativeLink()
  return (
    <>
      <div className="layer overflow-x-hidden bg-[#fff] pt-[96px] md:pt-[72px]">
        <div className="content" id={id}>
          <SectionTitle txt={data?.title}></SectionTitle>
          {data?.learnMoreHref && (
            <div className="mt-[12px] flex w-full items-center justify-center md:mt-[8px] md:justify-start">
              <a
                className="text-[20px] font-medium text-[#06A9E0] hover:underline l:text-[16px]"
                dangerouslySetInnerHTML={{
                  __html: data?.learnMoreTxt,
                }}
                href={setRelativeLink({ link: data?.learnMoreHref })}
              ></a>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10.5 8L14.5 12L10.5 16"
                  stroke="#06A9E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      <div className="mt-[48px] w-full overflow-hidden md:mt-[32px]">
        <div className="w-full">
          {data?.list && (
            // start_ai_generated
            <Marquee
              className="!overflow-x-visible"
              speed={data?.speed || 40}
              hoverSpeed={data?.hoverSpeed || 20}
            >
              {data?.list?.map((item, index) => {
                return <Card item={item} key={index + item?.title}></Card>
              })}
            </Marquee>
            // end_ai_generated
          )}
        </div>
      </div>
    </>
  )
}

export default Endorsement
