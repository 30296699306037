import { Text } from '@components/ui'
import cn from 'classnames'

function SectionTitle({ txt, className }) {
  return (
    txt && (
      <Text
        className={cn(
          'commonSolixStoreTitle text-center text-[48px] font-semibold leading-[52px] text-black md:text-left xl:text-[40px] xl:leading-[48px]',
          className
        )}
        html={txt}
      ></Text>
    )
  )
}

export default SectionTitle
