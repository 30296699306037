import cn from 'classnames'
import { useState, useRef, useMemo, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Text, Picture } from '@components/ui'
import {
  motion,
  useScroll,
  useTransform,
  useMotionTemplate,
} from 'framer-motion'

import s from './SolixTitle.module.css'

const SolixTitle = ({ title, subTitle, initColor, cs = {} }) => {
  const refTitle = useRef(null)

  const { scrollYProgress } = useScroll({
    target: refTitle,
    offset: ['end end', 'end 80%'],
  })

  const leftColor = useTransform(
    scrollYProgress,
    [0, 1],
    [initColor || '#000', '#00DB84']
  )
  const rightColor = useTransform(
    scrollYProgress,
    [0, 1],
    [initColor || '#000', '#00A9E0']
  )

  const background = useMotionTemplate`linear-gradient(263.53deg, ${leftColor} 15.78%, ${rightColor} 89.4%)`

  return (
    <motion.div className={cn(s.SolixTitle, cs.SolixTitle)}>
      <motion.h2 ref={refTitle} className={cn(s.title, cs.title)}>
        <motion.span
          style={{
            backgroundImage: background,
            ...cs?.span,
          }}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </motion.h2>
      {subTitle && (
        <p
          className={cn(s.subTitle, cs.subTitle)}
          dangerouslySetInnerHTML={{ __html: subTitle }}
        />
      )}
    </motion.div>
  )
}

export default SolixTitle
