import { sha256 } from '@lib/utils/tools'
import { useState, useEffect } from 'react'
import { Close } from '@components/icons/next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { cn, rmEmptyKeys } from '@components/helper'
import { Picture, Text, useUI } from '@components/ui'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import { parseMessage } from '@components/helper'
import { getAdCookie } from '@lib/utils/cookies'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { I18N_STORE_DOMAIN } from '@framework/const'

import Modal from 'react-modal'
import fetcher from '@lib/fetcher'
import s from './index.module.css'
import SolixButton from '@components/control/SolixButton'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { set } from 'js-cookie'

const Index = ({ id, data, pageHandle, locale, abtest }) => {
  const { recaptchaReady } = useUI()
  const { setRelativeLink, setHtmlRelativeLink } = useRelativeLink()
  const [isOpen, setIsOpen] = useState(false)
  const [policy, setPolicy] = useState(false)
  const [swiperRef, setSwiperRef] = useState(null)
  const [modalContent, setModalContent] = useState(null)

  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    if (recaptchaReady) {
      setLoading(false)
    }
  }, [recaptchaReady])

  const settings = {
    speed: 400,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 12,
    autoplay: data?.autoplay || false,
    modules: [Navigation, Autoplay, Pagination],
    breakpoints: {
      0: {
        slidesPerView: 1.7,
      },
      530: {
        slidesPerView: 2.5,
      },
      1024: {
        slidesPerView: 3.7,
      },
    },
    slideToClickedSlide: true,
  }

  useEffect(() => {
    const handleClick = (e) => {
      e.preventDefault()
      const sidebar = document.querySelector('.helpButtonEnabled')
      if (sidebar) {
        sidebar.click()
      }
    }
    setTimeout(() => {
      const button = document.getElementById('liveChatLink')
      if (button) {
        button.addEventListener('click', handleClick)
      }
    }, 10)
  }, [modalContent])

  const handleSubmit = async (e) => {
    e?.preventDefault()
    setLoading(true)
    try {
      const { register_source } = getAdCookie()
      const result = await fetcher({
        locale,
        action: 'activity',
        needRecaptcha: true,
        url: '/api/multipass/rainbowbridge/activities',
        method: 'POST',
        body: {
          register_source,
          genre: modalContent?.genre,
          email: email?.toLowerCase(),
          shopify_domain: I18N_STORE_DOMAIN[locale],
          single_brand_subscribe: true,
          sub_brand_type:
            modalContent?.subBrandType || 'anker_hes_balkonkraftwerk',
        },
      })
      setLoading(false)
      if (!result.errors) {
        setIsSuccess(true)
        setError(modalContent?.successTxt || '')
        pageGTMEvent({
          subscribe_hashed_email: email?.toLowerCase()
            ? sha256(email?.toLowerCase())
            : '', //推订阅的加密邮箱，加密算法sha256-20230726新增
        }) //标黄代码须在下方代码前
        pageGTMEvent({
          event: 'ga4Event',
          event_name: 'subscribe',
          event_parameters: {
            page_group: modalContent?.genre, //deals_type是指产品经理定义的、传给后端数据表的值；如果没有deals_type，且是底部订阅则传footer，其他情况留空
            position: 'pop_up', //传位置标记，比如首屏传masterbanner、弹窗传pop_up、最后一屏传lastbanner、悬浮框传sticky
          },
        })
        pageGTMEvent({ event_parameters: null })
        pageGTMEvent({
          event: 'uaEvent',
          eventCategory: 'subscribe',
          eventAction: modalContent?.genre, //deals_type是指产品经理定义的、传给后端数据表的值；如果没有deals_type，且是底部订阅则传footer，其他情况留空
          eventLabel: 'pop_up', //传位置标记，比如首屏传masterbanner、弹窗传pop_up、最后一屏传lastbanner、悬浮框传sticky
          nonInteraction: false,
        })
      } else {
        setError(parseMessage(result?.message || modalContent?.errorTxt))
      }
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError('')
      }, 5000)
    }
  }, [error])

  return (
    <section
      id={id}
      className={cn(
        s.serveWrap,
        'layer relative  bg-[#F8F9FA] py-[160px] l:p-0',
        {
          ['pt-0 md:pt-0']: data?.delPaddingTop,
          ['pb-0 md:pb-0']: data?.delPaddingBottom,
        }
      )}
      style={rmEmptyKeys({ background: data?.bgColor })}
    >
      <div className={cn(s.contentBox, 'content')}>
        <Picture
          source={data.mobImg}
          className="hidden h-full  w-full l:!block"
        />

        <div className={s.mobBox}>
          {data?.title && (
            <Text
              html={data.title}
              className={cn(
                'common_solixStore_new_title mb-[50px] text-[56px] font-[700] uppercase leading-[1.2] text-[#000] md:text-[32px] l:mb-[28px] l:pl-[20px] l:text-[#fff]',
                {
                  '!text-center': abtest,
                }
              )}
            />
          )}

          <ul className={s.col}>
            {data?.list?.map((item, index) => (
              <li
                className={cn({ ['cursor-pointer']: item?.child })}
                key={index}
                onClick={() => {
                  if (item?.child) {
                    setModalContent(item.child)
                    setIsOpen(true)
                  }
                }}
              >
                {item?.img && (
                  <Picture source={`${item?.img}`} className={cn(s.image)} />
                )}

                <div className={s.textBox}>
                  {item?.icon && (
                    <Picture
                      source={`${item?.icon}`}
                      className="mb-[27px] h-[18px] w-[151px]"
                    />
                  )}

                  <Text html={item?.title} className={s.itemTitle} />
                </div>
              </li>
            ))}
          </ul>

          <div className="hidden w-[calc(100%-21%)] flex-none md:w-full l:!block">
            <Swiper
              ref={swiperRef}
              {...settings}
              className={s.swiperBox}
              onSwiper={setSwiperRef}
            >
              {data?.list
                ?.filter((_, index) => index !== 1) // 过滤掉索引为1的元素
                .map((item, index) => (
                  <SwiperSlide key={index} className={s.swiperSlide}>
                    <Row
                      item={item}
                      data={data}
                      key={index}
                      pageHandle={pageHandle}
                      handleOpenModal={(child) => {
                        setModalContent(child)
                        setIsOpen(true)
                      }}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        overlayClassName={cn('Overlay', s.Overlay)}
        className={cn(s.Modal, 'Modal')}
        onRequestClose={() => setIsOpen(false)}
      >
        <Close className="fill-[#D0D0D5]" onClick={() => setIsOpen(false)} />
        {modalContent?.type == 'subscribe' ? (
          <div className={s.modalWrap}>
            {modalContent?.title && (
              <Text
                html={setHtmlRelativeLink({ html: modalContent?.title })}
                className="text-[24px] font-[700] md:text-[18px]"
              />
            )}

            {modalContent?.desc && (
              <Text
                html={setHtmlRelativeLink({ html: modalContent?.desc })}
                className="mb-[40px] mt-[25px] text-[16px] font-[500] text-[#6E6E73] md:mb-[20px] md:mt-[15px]"
              />
            )}

            <form onSubmit={handleSubmit}>
              <div className="relative mb-[14px] md:flex md:flex-col md:gap-[10px]">
                <input
                  required
                  type="email"
                  className="h-[48px] w-full border-[1px] border-[#D0D0D5] bg-[#fff] px-[18px] text-[16px] text-[#000] md:h-[40px] md:text-center"
                  placeholder={modalContent?.placeholder}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <SolixButton
                  className={s.btnBox}
                  type="submit"
                  disabled={loading}
                  loading={loading}
                  cns={{
                    content: 'flex items-center justify-center gap-[5px]',
                  }}
                >
                  {modalContent?.btnText}
                </SolixButton>
              </div>

              <div className="flex gap-[5px] ">
                <input
                  type="checkbox"
                  id="is-checked"
                  value={policy}
                  required
                  className={s.checkoutBox}
                  onChange={(e) => setPolicy(e.target.checked)}
                />
                <label htmlFor="is-checked">
                  <Text
                    className="text-[14px] font-[500] text-[#6E6E73]"
                    html={setHtmlRelativeLink({ html: modalContent?.policy })}
                  />
                </label>
              </div>

              {error && (
                <Text
                  className={cn(
                    'pt-[10px] text-[14px] font-medium text-[red]',
                    { ['text-[#00CA2C]']: isSuccess }
                  )}
                  html={error}
                />
              )}
            </form>
          </div>
        ) : (
          <>
            <ul className={s.modalCol}>
              {modalContent?.list?.map((item, index) => (
                <li key={index}>
                  {item?.title && (
                    <Text
                      html={setHtmlRelativeLink({ html: item?.title })}
                      className="text-[24px] font-[700] md:text-[18px]"
                    />
                  )}
                  {item?.desc && (
                    <Text
                      html={setHtmlRelativeLink({ html: item.desc })}
                      className="mt-[25px] text-[16px] font-[500] text-[#6E6E73] md:mt-[15px]"
                    />
                  )}
                </li>
              ))}
            </ul>
            {modalContent?.cooperation?.length && (
              <div className="mt-[30px] flex flex-wrap gap-[3px] md:mt-[15px]">
                {modalContent?.cooperation?.map((item, index) => (
                  <Picture
                    key={index}
                    source={`${item?.img}`}
                    className="h-[21px] w-[34px]"
                  />
                ))}
              </div>
            )}
            {modalContent?.learnMore && (
              <a
                className="mt-[25px] flex items-center text-[18px] font-[600] text-[#00A9E0] hover:underline md:mt-[15px] md:text-[16px]"
                href={`${setRelativeLink({ link: modalContent?.link })}?ref=${
                  data?.ref || pageHandle
                }`}
              >
                <Text html={modalContent?.learnMore} />
                <ChevronRightIcon className="h-[18px] w-[18px]" />
              </a>
            )}
          </>
        )}
      </Modal>

      <style jsx global>{`
        #${id} .swiper-button-prev,
        #${id} .swiper-button-next {
          width: 18px;
          opacity: 0;
          height: 33px;
          color: #000;
          transition: all 200ms ease-out;
        }

        #${id} .swiper-button-prev {
          left: 40px;
        }

        #${id} .swiper-button-next {
          right: 40px;
        }

        #${id} .${s.swiperBox}:hover .swiper-button-prev,
        #${id} .${s.swiperBox}:hover .swiper-button-next {
          opacity: 1;
        }

        #${id} .${s.swiperBox}:hover .swiper-button-disabled {
          opacity: 0.35;
        }

        #${id} .swiper-button-next:after,
        #${id} .swiper-rtl .swiper-button-prev:after,
        #${id} .swiper-button-prev:after,
        #${id} .swiper-rtl .swiper-button-next:after {
          font-size: 33px;
        }

        @media (max-width: 769px) {
          #${id} .swiper-button-next,
          #${id} .swiper-button-prev {
            display: none;
          }
        }
      `}</style>
    </section>
  )
}

export default Index

const Row = ({ item, handleOpenModal }) => {
  return (
    <div
      className={s.hoverBox}
      onClick={() => {
        if (item?.child) {
          handleOpenModal(item.child)
        }
      }}
    >
      <div className="mt-[2rem] w-full max-w-[40%] md:mt-0 md:max-w-[100px]">
        <Picture
          source={`${item?.mobImg}`}
          className={cn(
            'aspect-h-[140] aspect-w-[140] w-full md:aspect-h-[100] md:aspect-w-[100]'
          )}
        />
      </div>
      <Text html={item.title} className={s.itemTitle} />
    </div>
  )
}
