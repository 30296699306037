import { useState } from 'react'
import { Picture, Text } from '@components/ui'

import { Swiper, SwiperSlide } from 'swiper/react'
import { cn, rmEmptyKeys } from '@components/helper'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import s from './index.module.css'

const Index = ({ id, data, pageHandle, locale }) => {
  const [swiperRef, setSwiperRef] = useState(null)

  const settings = {
    speed: 400,
    navigation: true,
    slidesPerView: 1,
    spaceBetween: 16,
    autoplay: data?.autoplay || false,
    modules: [Navigation, Autoplay, Pagination],
    breakpoints: {
      0: {
        slidesPerView: 1.4,
        spaceBetween: '8px',
      },
      769: {
        slidesPerView: 1.4,
        spaceBetween: '8px',
      },
      1024: {
        slidesPerView: 3.3,
      },
    },
    slideToClickedSlide: true,
  }

  return (
    <section
      id={id}
      className={cn(
        'layer relative overflow-hidden bg-[#F3F4F5] py-[160px] md:py-[80px]',
        {
          ['pt-0 md:pt-0']: data?.delPaddingTop,
          ['pb-0 md:pb-0']: data?.delPaddingBottom,
        }
      )}
      style={rmEmptyKeys({ backgroundColor: data?.bgColor })}
    >
      <div className="content !max-w-[1410px]">
        {data?.title && (
          <Text
            html={data.title}
            className="text-[56px] font-[700] uppercase leading-[1.2] text-[#000] md:text-[32px]"
          />
        )}

        <Swiper
          loop={false}
          ref={swiperRef}
          {...settings}
          onSwiper={setSwiperRef}
          className={cn(
            'mt-[50px] !overflow-visible md:mt-[30px]',
            s.swiperBox
          )}
        >
          {data?.list?.map((item, index) => {
            return (
              <SwiperSlide className="relative" key={index}>
                <Picture
                  source={`${item?.img}, ${item?.mobImg || item?.img} 768`}
                  className={cn(
                    'aspect-h-[300] aspect-w-[416] w-full md:aspect-h-[260] md:aspect-w-[250]'
                  )}
                />
                {item?.text && (
                  <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center">
                    <Text
                      html={item.text}
                      className="text-[24px] font-[700] uppercase text-[#fff] md:mt-[20px] md:text-[20px]"
                    />
                  </div>
                )}
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>

      <style jsx global>{`
        #${id} .swiper-button-prev,
        #${id} .swiper-button-next {
          width: 18px;
          opacity: 0;
          height: 33px;
          color: #000;
          transition: all 200ms ease-out;
        }

        #${id} .swiper-button-prev {
          left: 40px;
        }

        #${id} .swiper-button-next {
          right: 40px;
        }

        #${id} .${s.swiperBox}:hover .swiper-button-prev,
        #${id} .${s.swiperBox}:hover .swiper-button-next {
          opacity: 1;
        }

        #${id} .${s.swiperBox}:hover .swiper-button-disabled {
          opacity: 0.35;
        }

        #${id} .swiper-button-next:after,
        #${id} .swiper-rtl .swiper-button-prev:after,
        #${id} .swiper-button-prev:after,
        #${id} .swiper-rtl .swiper-button-next:after {
          font-size: 33px;
        }

        @media (max-width: 769px) {
          #${id} .swiper-button-next,
          #${id} .swiper-button-prev {
            display: none;
          }
        }
      `}</style>
    </section>
  )
}

export default Index
