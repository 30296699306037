import fetcher from '@lib/fetcher'
import { I18N_STORE_DOMAIN } from '@shopify/const'
const getProductCouponsBySkus = async (locale, skus, formatData, channel) => {
  const url = `/api/multipass/shopifyservices/coupons/by_skus`
  const result = await fetcher({
    url: channel ? url + '?' + channel : url,
    locale,
    method: 'GET',
    repeatKey: 'skus[]',
    body: {
      'skus[]': skus,
      shopify_domain: I18N_STORE_DOMAIN[locale],
    },
  })
  if (!result?.errors && result?.data) {
    const resultData = result?.data
    if (formatData) {
      return formatData(resultData)
    } else {
      return resultData
    }
  } else {
    return false
  }
}

export default getProductCouponsBySkus
