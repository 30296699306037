import { Picture } from '@components/ui'
import styles from './PeaceOfMind.module.css'
import cn from 'classnames'
import SectionTitle from '@components/layout/SectionTitle'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import Button from '@components/control/Button'
import SolixButton from '@components/control/SolixButton'
import { useRelativeLink } from '@commerce/product/use-relative-link'
const PeaceOfMind = ({ data, pageType }) => {
  const { setRelativeLink } = useRelativeLink()
  let itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: {
      x: '-130%',
      transition: { duration: 0.3 },
    },
  }
  return (
    <div className="overflow-hidden ">
      {data?.title && (
        <SectionTitle
          subTitle={data?.subTitle}
          title={data?.title}
          className={styles.title_box}
          jumpOver={data?.jumpOver}
        />
      )}
      <section className={cn(styles.peaceOfMind_layer, 'layer', 'pt-[16px]')}>
        <motion.div
          layout
          className={cn(styles.peaceOfMind_content, 'content')}
        >
          {data?.peaceOfMindList &&
            data?.peaceOfMindList.map((v, i) => {
              const link =
                setRelativeLink({ link: v?.link }) +
                (data?.ref ? `?ref=${data?.ref}` : '?ref=homePageBanner_pps')
              return (
                <motion.div
                  layout
                  key={`peaceOfMindList-${i}`}
                  className={styles.peaceOfMind_content_box}
                  onClick={() => {
                    window.location.href = link
                  }}
                >
                  <div className="overflow-hidden">
                    <Picture
                      className={styles.peaceOfMind_content_img}
                      source={`${v.imgUrl},${v.imgUrlMob} 768`}
                    />
                  </div>
                  <motion.div
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.6 }}
                    variants={{
                      show: {
                        transition: {
                          duration: 0.3,
                          staggerChildren: 0.05,
                        },
                      },
                      hidden: {
                        transition: {
                          duration: 0.3,
                        },
                      },
                    }}
                    layout
                    className={styles.peaceOfMind_content_text_box}
                  >
                    <div className=" h-full w-full md:px-[24px]">
                      <div className="md_content_grid  flex h-full flex-col">
                        <motion.p
                          variants={itemVariants}
                          layout
                          className={styles.peaceOfMind_content_title}
                          dangerouslySetInnerHTML={{
                            __html: v?.title,
                          }}
                        />
                        <motion.p
                          variants={itemVariants}
                          layout
                          className={styles.peaceOfMind_content_text}
                          dangerouslySetInnerHTML={{
                            __html: v?.text,
                          }}
                        />
                        {v?.learnMore && (
                          <motion.p variants={itemVariants} layout>
                            {pageType === 'powerStation' ||
                            pageType === 'hes' ? (
                              <>
                                <SolixButton>
                                  <a className="block" href={link}>
                                    {v?.learnMore}
                                  </a>
                                </SolixButton>
                              </>
                            ) : (
                              <Button className={styles.btn}>
                                <motion.a
                                  className="block"
                                  href={link}
                                  target="_self"
                                  dangerouslySetInnerHTML={{
                                    __html: v?.learnMore,
                                  }}
                                />
                              </Button>
                            )}
                          </motion.p>
                        )}
                      </div>
                    </div>
                  </motion.div>
                </motion.div>
              )
            })}
        </motion.div>
      </section>
    </div>
  )
}
export default PeaceOfMind
