import { Picture, Text } from '@components/ui'
import SectionTitle from '../SectionTitle'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'

import { normalizeStorefrontProduct } from '@components/normalize'
import { Arrow } from '../Arrow'
import Discount from '@components/product/Discount/Discount'
import { Cta, Price } from '@components/product'
import { useMemo, useState, useEffect } from 'react'
import useCoupons from '@lib/hooks/useCoupon'
import useProduct from '@shopify/product/use-product'
import { atobID, getProductTag } from '@lib/utils/tools'
import BannerLink from '@components/common/BannerLink'
import { useRouter } from 'next/router'
import s from './index.module.css'
import aniStyle from '../index.module.css'
import Tab from '../Tab'
import { useDevice } from 'react-use-device'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const svgTxt = `<span style="display: inline-block; vertical-align: middle;"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
<path d="M5.33325 4L8.66659 7.33333L5.33325 10.6667" stroke="#00A9E1" strokeWidth="1.575" stroke-linecap="round"/>
</svg></span>`

const Card = ({ product, pageHandle, data, locale, index }) => {
  console.log('product111', product)
  return (
    <div
      className={cn(
        'w-full cursor-pointer !overflow-visible',
        aniStyle?.card_parent
      )}
    >
      <div className="relative z-[2] mx-auto w-[60%]">
        <Picture
          source={`${product?.custom_img || product.image},${
            product?.custom_mbImg || product?.custom_img || product.image
          } 768}`}
          className={cn(
            'aspect-h-[287] aspect-w-[287]',
            aniStyle?.card_parent_img
          )}
          imgClassName="w-full h-full object-cover"
        />
        <BannerLink
          to={`${
            product?.customLink ||
            `${locale === 'us' ? '' : '/' + locale}/products/${
              product.handle
            }?variant=${atobID(product.variantId)}&ref=${
              data?.ref || `pps_productBanner${index + 1}`
            }`
          } `}
        ></BannerLink>
      </div>
      <div
        className={cn(
          'aspect-h-[360] aspect-w-[410] mt-[-30%] h-0 rounded-[10px] bg-white md:aspect-h-[300] md:aspect-w-[342]',
          aniStyle?.card_parent_content
        )}
      >
        <div className="box-border flex w-full flex-col items-center px-[24px] pt-[30%] text-center md:pt-[26%]">
          {!data?.hideDiscount && (
            <Discount data={data} product={product} className="left-[0px]" />
          )}
          <Text
            className="mx-auto mb-[8px] h-[20px] text-[14px] font-semibold leading-[16px] text-[#00DB85]"
            html={getProductTag({
              variant: {
                variantTags: product?.variantTags,
              },
              custom: product?.tagTxt,
            })}
          ></Text>
          <div className="relative h-[48px] md:h-[60px]">
            <Text
              className="line-clamp-2 text-[20px] font-bold leading-[24px] md:line-clamp-3 md:text-[18px] md:leading-[22px]"
              html={
                product?.variantMetafields?.infos?.page_short_title ||
                product?.custom_title ||
                product.title
              }
            ></Text>
            <BannerLink
              to={`${
                product?.customLink ||
                `${locale === 'us' ? '' : '/' + locale}/products/${
                  product.handle
                }?variant=${atobID(product.variantId)}&ref=${
                  data?.ref || `pps_productBanner${index + 1}`
                }`
              } `}
            ></BannerLink>
          </div>

          <div className="mt-[8px]">
            <Price
              copy={data}
              product={product}
              s={{
                price: '!text-[20px] !text-[#000] !font-medium l:!text-[16px]',
                basePrice:
                  '!text-[16px] !text-[#6E6E73] !font-medium l:!text-[12px]',
              }}
            />
          </div>
          <div className="mx-auto mt-[20px] h-[46px] w-[160px] md:mt-[8px] md:h-[32px] md:w-[120px] md-xl:mt-[24px] md-xl:h-[40px] md-xl:w-[150px]">
            <Cta
              solixButton={true}
              copy={data}
              buttons={
                data?.buttons || [
                  {
                    type: 'LearnMore',
                    theme: 'primary',
                    custom_height: 'md:!h-[32px] md:!leading-[32px]',
                    link: product?.customLink,
                  },
                ]
              }
              product={product}
              pageHandle={`pps_productBanner${index + 1}`}
              gtmParam={{
                itemListName: data?.title,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function SellRecommend({ data, pageHandle, id }) {
  const { tabs } = data
  const { setRelativeLink } = useRelativeLink()

  const [activeTab, setActiveTab] = useState()

  const curTabInfo = useMemo(() => {
    return tabs?.find((tab) => tab.tabId === activeTab)
  }, [tabs, activeTab])

  const curProducts = useMemo(() => {
    return curTabInfo?.products
  }, [curTabInfo])

  // start_ai_generated
  const handles = curProducts?.map((subItem) => subItem.handle).join(',')

  const skus = curProducts?.map((subItem) => subItem.sku)

  const { data: result, isLoading } = useProduct({
    handles,
  })
  // end_ai_generated

  const { coupons } = useCoupons(skus)
  const [isStart, setStart] = useState(true)
  const [isEnd, setEnd] = useState(false)
  const { locale } = useRouter()

  const { isMOBILE } = useDevice()

  useEffect(() => {
    // 产品归类
    if (!activeTab) {
      typeof setActiveTab === 'function' && setActiveTab(tabs[0]?.tabId)
    }
  }, [activeTab, setActiveTab, tabs])

  return (
    <>
      <div className="layer overflow-x-hidden bg-[#F5F5F7] pt-[120px] md:pr-0 md:pt-[72px]">
        <div className="content md:overflow-hidden" id={id}>
          <SectionTitle txt={data?.title}></SectionTitle>
          <Tab
            tabs={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          ></Tab>
          {curProducts && (
            <div className="mt-[8px] w-screen">
              <div className={cn(s.custom_max_w, 'relative')}>
                <Swiper
                  className="!overflow-visible"
                  navigation={{
                    nextEl: '.custom-swiper-button-next',
                    prevEl: '.custom-swiper-button-prev',
                  }}
                  modules={[Navigation]}
                  onProgress={(swiper) => {
                    setStart(swiper.isBeginning)
                    setEnd(swiper.isEnd)
                  }}
                  breakpoints={{
                    0: {
                      spaceBetween: '10px',
                      slidesPerView: 1.1,
                    },
                    768: {
                      spaceBetween: '12px',
                      slidesPerView: 1.5,
                    },
                    1024: {
                      spaceBetween: '16px',
                      slidesPerView: 2,
                    },
                    1440: {
                      spaceBetween: '24px',
                      slidesPerView: 3,
                    },
                  }}
                >
                  {curProducts?.map((jItem, jIndex) => {
                    const jProduct = normalizeStorefrontProduct(
                      jItem,
                      result,
                      coupons
                    )
                    if (!jProduct) return null
                    return (
                      <SwiperSlide key={jIndex + jItem?.sku}>
                        <Card
                          index={jIndex}
                          locale={locale}
                          product={jProduct}
                          pageHandle={pageHandle}
                          data={data}
                        ></Card>
                      </SwiperSlide>
                    )
                  })}
                  {curTabInfo?.viewAllHref && !isMOBILE && (
                    <SwiperSlide key={curTabInfo?.viewAllHref}>
                      <div className=" mt-[70%] w-[140px]">
                        <a
                          href={setRelativeLink({
                            link: curTabInfo?.viewAllHref,
                          })}
                          className="text-[17px] font-medium leading-[21px] text-[#00BEFA] hover:underline"
                          dangerouslySetInnerHTML={{
                            __html: curTabInfo?.viewAll + svgTxt,
                          }}
                        ></a>
                      </div>
                    </SwiperSlide>
                  )}
                </Swiper>
                <Arrow isStart={isStart} isEnd={isEnd}></Arrow>
              </div>
            </div>
          )}
          {curTabInfo?.viewAllHref && (
            <a
              href={setRelativeLink({ link: curTabInfo?.viewAllHref })}
              className="ml-[-24px] mt-[16px] block text-center text-[16px] font-medium leading-[20px] text-[#00BEFA] underline min-md:hidden"
              dangerouslySetInnerHTML={{
                __html: curTabInfo?.viewAll + svgTxt,
              }}
            ></a>
          )}
        </div>
      </div>
    </>
  )
}

export default SellRecommend
