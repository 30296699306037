import cn from 'classnames'
import { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import isMobile from 'ismobilejs'
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/outline'
import Banner from '@components/banner/HomeBanner'
import s from './index.module.css'

// start_ai_generated
const PrevArrow = ({ onClick, active, c = '' }) => (
  <button
    onClick={onClick}
    className={cn(c, s.arrow, s.arrowLeft, 'arrow-left')}
  >
    <ChevronLeftIcon />
  </button>
)
// end_ai_generated

// start_ai_generated
const NextArrow = ({ onClick, active, c = '' }) => (
  <button
    onClick={onClick}
    className={cn(c, s.arrow, s.arrowRight, 'arrow-right')}
  >
    <ChevronRightIcon />
  </button>
)
// end_ai_generated

// 需要依赖特定数据结构 data
const HesSwiperBanner = ({
  lang,
  gtmRef,
  relatedProducts,
  pageType,
  customDeviceSizes = [360, 767, 1024, 1280, 1440, 1540, 1920],
}) => {
  const [mobile, setMobile] = useState(false)
  const [swiperRef, setSwiperRef] = useState(null)
  const HesSwiperBannerRef = useRef()

  const getProduct = (handle) => {
    return relatedProducts?.find(
      (p) => handle?.toLowerCase() === p?.result?.handle?.toLowerCase()
    )?.result
  }

  // start_ai_generated
  useEffect(() => {
    setMobile(isMobile(window.navigator).phone)
  }, [])
  // end_ai_generated

  const bgMap = {
    light: '#000',
    dark: '#ffffff',
  }

  // 当组件完全出现在页面时才开启自动
  useEffect(() => {
    if (swiperRef?.autoplay) swiperRef.autoplay.stop()
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [swiperRef])
  const handleScroll = () => {
    if (
      HesSwiperBannerRef?.current?.getBoundingClientRect()?.bottom <=
      window.innerHeight
    ) {
      if (swiperRef?.autoplay && !swiperRef?.autoplay?.running)
        swiperRef.autoplay.start()
    }
  }

  return (
    <div
      ref={HesSwiperBannerRef}
      className={cn(
        'relative flex flex-col',
        `${lang?.abtest ? 'hidden' : 'block'}`,
        `${gtmRef}`
      )}
    >
      {lang?.bannerList?.length > (lang?.showSwiperNum || 2) ? (
        <Swiper
          onSwiper={setSwiperRef}
          autoplay={{
            delay: lang?.autoplaySpeed || 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
          loop={true}
          modules={[Navigation, Pagination, Autoplay]}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              const theme = lang?.bannerList[index]?.theme
              return `<li class="${className}"><div style=" background-color: ${bgMap[theme]}" class="slick-dots-progress"></div></li>`
            },
          }}
          navigation={{
            nextEl: '.arrow-right',
            prevEl: '.arrow-left',
          }}
          onAutoplayTimeLeft={(swiper, timeLeft, percentage) => {
            document.querySelector(
              '.swiper-pagination-bullet-active .slick-dots-progress'
            ).style.width = `${(1 - percentage) * 100}%`
          }}
          onSlideChange={(swiper) => {
            let slickDotsProgress = document.querySelectorAll(
              '.slick-dots-progress'
            )
            slickDotsProgress.forEach((item) => {
              item.style.width = '0%'
            })
          }}
          speed={lang?.speed || 300}
          className={cn(s.slider)}
        >
          {/* start_ai_generated */}
          {lang?.bannerList?.map((item, i) => (
            <SwiperSlide className={s.sliderCart} key={`swiperBanner-${i}`}>
              <Banner
                gtmRef={`${gtmRef}_${i + 1}`}
                product={getProduct(item?.handle)}
                lang={item}
                id={`swiperBanner-${i}`}
                pageType={pageType}
                callComponent="HesSwiperBanner"
                customDeviceSizes={
                  pageType == 'hes' || pageType == 'powerStation'
                    ? customDeviceSizes
                    : null
                }
              />
            </SwiperSlide>
          ))}
          {/* end_ai_generated */}
          <PrevArrow></PrevArrow>
          <NextArrow></NextArrow>
        </Swiper>
      ) : (
        lang?.bannerList?.map((item, i) => (
          <div className={s.sliderCart} key={`swiperBanner-${i}`}>
            {/* start_ai_generated */}
            <Banner
              gtmRef={`${gtmRef}_${i + 1}`}
              product={getProduct(item?.handle)}
              lang={item}
              id={`swiperBanner-${i}`}
              pageType={pageType}
              callComponent="HesSwiperBanner"
              customDeviceSizes={
                pageType == 'hes' || pageType == 'powerStation'
                  ? customDeviceSizes
                  : null
              }
            />
            {/* end_ai_generated */}
          </div>
        ))
      )}
      <style jsx global>
        {`
          .${s.slider} .swiper-pagination {
            bottom: 20px;
          }
          .${s.slider} li {
            width: 20px;
            height: 3px;
            background-color: #949494;
            border-radius: 4px;
            transition: all 0.3s ease-out;
            overflow: hidden;
            opacity: 1;
          }
          .${s.slider} .swiper-pagination-bullet-active {
            width: 80px;
          }
          .${s.slider} .slick-dots-progress {
            width: 0%;
            height: 100%;
          }
          .${s.slider} .swiper-pagination-bullet-active .slick-dots-progress {
            background-color: #000000;
          }
          @media (max-width: 767px) {
            .${s.slider} li {
              width: 16px;
            }
            .${s.slider} .swiper-pagination-bullet-active {
              width: 40px;
            }
          }
        `}
      </style>
    </div>
  )
}

export default HesSwiperBanner
