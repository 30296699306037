/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useRef, useMemo, useEffect } from 'react'
import cn from 'classnames'
import throttle from 'lodash.throttle'
import { useRouter } from 'next/router'
import { Text, Picture } from '@components/ui'
import { motion, useScroll, useTransform } from 'framer-motion'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './SectionTitle.module.css'
import styles from '@components/ui/TextsLayout/styles'

const SectionTitle = ({
  children,
  className,
  style = {},
  cns = {},
  subTitle,
  title,
  jumpOver,
  blogLink,
  text,
  textClassName,
  animation = 'static',
  gs = {},
}) => {
  const [isXxl, setIsXxl] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const { setRelativeLink } = useRelativeLink()

  const [w_normal, w_active] = useMemo(() => {
    if (isXxl) {
      return [160, 120]
    } else if (!isMobile && !isXxl) {
      return [160, 80]
    } else {
      return [80, 60]
    }
  }, [isXxl, isMobile])

  const [h_normal, h_active] = useMemo(() => {
    if (!isMobile) {
      return [80, 40]
    } else {
      return [40, 20]
    }
  }, [isMobile])

  const resizeWindow = throttle(() => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth >= 1920) {
      setIsXxl(true)
      setIsMobile(false)
    } else if (offsetWidth <= 768) {
      setIsMobile(true)
      setIsXxl(false)
    }
  }, 300)

  const refTitle = useRef(null)

  const { scrollYProgress } = useScroll({
    target: refTitle,
    offset: ['start end', 'end center'],
  })

  const [over, setOver] = useState(false)

  useEffect(() => {
    return scrollYProgress.onChange((latest) => {
      if (latest >= 1) setOver(true)
    })
  }, [])

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [resizeWindow])

  let itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.5 },
    },
    hidden: {
      x: '-120%',
      transition: { duration: 0.5 },
    },
  }

  const link = blogLink?.link ? setRelativeLink({ link: blogLink?.link }) : ''

  return (
    <div className={cn(s.root, 'layer', className)} style={style}>
      {animation === 'static' && (
        <div className={cn(s.content, 'content')}>
          <div
            ref={refTitle}
            className={cn(s.titleBoxPadding, gs?.titleBoxPadding)}
          >
            <div className={s.titleBox}>
              {children ? (
                children
              ) : (
                <>
                  <p className={cn(s.subTitle, cns?.subTitle, textClassName)}>
                    {subTitle}
                  </p>
                  {blogLink ? (
                    <div className={cn(s.title_flex, textClassName)}>
                      <h2
                        className={cn(
                          s.title,
                          cns?.subTitle,
                          textClassName,
                          'title'
                        )}
                        dangerouslySetInnerHTML={{
                          __html: title,
                        }}
                      ></h2>
                      {blogLink.text && (
                        <a
                          href={link}
                          target="_self"
                          className={s.blogLink}
                          dangerouslySetInnerHTML={{
                            __html: blogLink.text,
                          }}
                        />
                      )}
                    </div>
                  ) : (
                    <h2
                      className={cn(s.title, cns?.subTitle, textClassName)}
                      dangerouslySetInnerHTML={{
                        __html: title,
                      }}
                    ></h2>
                  )}
                  <div className="grid grid-cols-12 gap-5">
                    {text && (
                      <p
                        className={cn(s.text, textClassName)}
                        dangerouslySetInnerHTML={{
                          __html: text,
                        }}
                      ></p>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {animation === 'scale' && (
        <div className={cn(s.content, 'content')}>
          {jumpOver && (
            <motion.div
              layout
              className={s.titleBoxPadding}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true, amount: 0.6 }}
              variants={{
                show: {
                  transition: {
                    duration: 0.5,
                    staggerChildren: 0.05,
                  },
                },
                hidden: {
                  transition: {
                    duration: 0.5,
                  },
                },
              }}
            >
              <motion.div layout className={s.titleBox}>
                {children ? (
                  children
                ) : (
                  <>
                    <motion.p
                      variants={itemVariants}
                      className={cn(s.subTitle, cns?.subTitle, textClassName)}
                      dangerouslySetInnerHTML={{
                        __html: subTitle,
                      }}
                    ></motion.p>
                    {blogLink ? (
                      <motion.div
                        variants={itemVariants}
                        className={cn(s.title_flex, textClassName)}
                      >
                        <motion.h2
                          variants={itemVariants}
                          className={cn(s.title, cns?.subTitle, textClassName)}
                          dangerouslySetInnerHTML={{
                            __html: title,
                          }}
                        ></motion.h2>
                        {blogLink?.text && (
                          <a
                            href={link}
                            target="_self"
                            className={s.blogLink}
                            dangerouslySetInnerHTML={{
                              __html: blogLink?.text,
                            }}
                          />
                        )}
                      </motion.div>
                    ) : (
                      <motion.h2
                        variants={itemVariants}
                        className={cn(s.title, cns?.subTitle, textClassName)}
                        dangerouslySetInnerHTML={{
                          __html: title,
                        }}
                      ></motion.h2>
                    )}
                    {text && (
                      <motion.p
                        variants={itemVariants}
                        className={cn(s.text, textClassName)}
                        dangerouslySetInnerHTML={{
                          __html: text,
                        }}
                      ></motion.p>
                    )}
                  </>
                )}
              </motion.div>
            </motion.div>
          )}
          {!jumpOver && (
            <motion.div
              layout
              ref={refTitle}
              style={{
                paddingTop: useTransform(
                  scrollYProgress,
                  [0, 1],
                  over ? [w_active, w_active] : [w_normal, w_active]
                ),
                paddingBottom: useTransform(
                  scrollYProgress,
                  [0, 1],
                  over ? [h_active, h_active] : [h_normal, h_active]
                ),
              }}
              className={s.titleBoxPadding}
            >
              <motion.div
                layout
                style={{
                  scale: useTransform(
                    scrollYProgress,
                    [0, 1],
                    over ? [1, 1] : [1.6, 1]
                  ),
                }}
                className={s.titleBox}
              >
                {children ? (
                  children
                ) : (
                  <>
                    <p className={cn(s.subTitle, cns?.subTitle, textClassName)}>
                      {subTitle}
                    </p>
                    {blogLink ? (
                      <div className={cn(s.title_flex, textClassName)}>
                        <h2
                          className={cn(s.title, cns?.subTitle, textClassName)}
                        >
                          {title}
                        </h2>
                        {blogLink?.text && (
                          <a
                            href={link}
                            target="_self"
                            className={s.blogLink}
                            dangerouslySetInnerHTML={{
                              __html: blogLink?.text,
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <h2 className={cn(s.title, cns?.subTitle, textClassName)}>
                        {title}
                      </h2>
                    )}
                    {text && (
                      <p className={cn(s.text, textClassName)}>{text}</p>
                    )}
                  </>
                )}
              </motion.div>
            </motion.div>
          )}
        </div>
      )}
    </div>
  )
}

export default SectionTitle
