import { useState } from 'react'
import { Picture, Text } from '@components/ui'
import { cn, rmEmptyKeys } from '@components/helper'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './index.module.scss'
import ProgressBar from '../ProgressBar'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'

const Index = ({ data, id, pageHandle, abtest }) => {
  const { setRelativeLink } = useRelativeLink()
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <>
      <style jsx global>{`
        #${id} .swiper-button-prev,
        #${id} .swiper-button-next {
          width: 18px;
          opacity: 0;
          height: 33px;
          color: #fff;
          transition: all 200ms ease-out;
        }

        #${id} .swiper-button-prev {
          left: 40px;
        }

        #${id} .swiper-button-next {
          right: 40px;
        }

        #${id} .swiperBox:hover .swiper-button-prev,
        #${id} .swiperBox:hover .swiper-button-next {
          opacity: 1;
        }

        #${id} .swiperBox:hover .swiper-button-disabled {
          opacity: 0.35;
        }

        #${id} .swiper-button-next:after,
        #${id} .swiper-rtl .swiper-button-prev:after,
        #${id} .swiper-button-prev:after,
        #${id} .swiper-rtl .swiper-button-next:after {
          font-size: 33px;
        }

        #${id} .${s.swiperBox} .swiper-pagination {
          bottom: -40px;
          display: flex;
          gap: 5px;
          justify-content: center;
        }

        @media (max-width: 769px) {
          #${id} .swiper-button-next,
          #${id} .swiper-button-prev {
            display: none;
          }

          #${id} .${s.swiperBox} .swiper-pagination {
            bottom: -30px;
          }
        }
      `}</style>
      <section
        id={id}
        className={cn(
          'layer relative overflow-hidden bg-[#F3F4F5] py-[160px] md:py-[80px]',
          {
            ['pt-0 md:pt-0']: data?.delPaddingTop,
            ['pb-0 md:pb-0']: data?.delPaddingBottom,
          }
        )}
        style={rmEmptyKeys({ background: data?.bgColor })}
      >
        <div className="content">
          {data?.title && (
            <Text
              html={data.title}
              className={cn(
                'mb-[50px] text-[56px] font-[700] uppercase leading-[1.2] text-[#000] md:mb-[28px] md:text-[32px]',
                {
                  '!text-center': abtest,
                }
              )}
            />
          )}

          <div className={s.col}>
            <Swiper
              onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
              modules={[Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1.1,
                  spaceBetween: '8px',
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: '12px',
                },
              }}
              navigation={true}
              speed={300}
              slideToClickedSlide={true}
              className={cn(
                'relative w-full h-full !overflow-visible swiperBox'
              )}
            >
              {data?.list?.map((item, index) => (
                <SwiperSlide
                  className="relative block"
                  key={`homeSolutions-${index}`}
                >
                  <li
                    key={index}
                    className={cn(s.itemBox, '!w-full !min-w-full', {
                      [s.activeItem]: activeIndex === index,
                    })}
                  >
                    <div className={cn('absolute right-0 top-0 h-full w-full')}>
                      <Picture
                        source={`${item?.img_b || item?.img}, ${
                          item?.mobImg_b ||
                          item?.img_b ||
                          item?.mobImg ||
                          item?.img
                        } 768`}
                        className={s.image}
                        style={{
                          '--objectPosition':
                            item?.styles?.objectPosition || '100% 100%',
                          '--mobObjectPosition':
                            item?.styles?.mobObjectPosition || '100% 100%',
                        }}
                      />
                    </div>
                    <div className={cn(s.absoluteBox, 'box-border')}>
                      <div className={s.contentWrap}>
                        <Text
                          html={item?.title_b || item.title}
                          className={s.itemTitle}
                        />
                        <div className={s.descWrap}>
                          <Text
                            html={item?.desc_b || item?.desc}
                            className={s.desc}
                          />
                          <a
                            className={s.learnMore}
                            href={`${setRelativeLink({
                              link: item?.link,
                            })}?ref=${
                              data?.ref ? data?.ref + (index + 1) : pageHandle
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: item?.learnMore_b || item?.learnMore,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <ProgressBar
                      s={{
                        progressBarBox:
                          activeIndex === index ? s.progressBarBox : '',
                      }}
                    />
                  </li>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  )
}

export default Index
