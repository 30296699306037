import s from './RecallNavigation.module.css'
import cn from 'classnames'
import { useRelativeLink } from '@commerce/product/use-relative-link'
const RecallNavigation = ({ data }) => {
  const { setRelativeLink } = useRelativeLink()
  return (
    <section>
      <div className="layer bg-[#F7F8FA]">
        <div className={cn('content ', s.content)}>
          <div className="flex justify-center">
            <p
              className={s.text}
              dangerouslySetInnerHTML={{
                __html: data?.text,
              }}
            />
            <a
              className={s.learnMore}
              href={setRelativeLink({ link: data?.learnMoreLink })}
              dangerouslySetInnerHTML={{
                __html:
                  data?.learnMore + `<span class="hidden">${data?.text}</span>`,
              }}
            ></a>
          </div>
        </div>
      </div>
    </section>
  )
}
export default RecallNavigation
