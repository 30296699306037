import getProductCouponsBySkus from '@lib/apis/get-product-coupons-by-skus'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

const useCoupons = (skus: String[], disable = false) => {
  // console.log('disable:', disable)
  const [coupons, setCoupons] = useState({})
  const [loading, setLoading] = useState(false)
  const { locale } = useRouter()

  useEffect(() => {
    const getData = async () => {
      if (skus && skus.length > 0 && !disable) {
        setLoading(true)
        const result = await getProductCouponsBySkus(locale, skus)

        setCoupons(result)
        setLoading(false)
      }
    }
    getData()
  }, [locale, skus?.join()])

  return { coupons, loading }
}

export default useCoupons
