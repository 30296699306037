import { Picture, Text } from '@components/ui'
import SectionTitle from '../SectionTitle'
import cn from 'classnames'
import BannerLink from '@components/common/BannerLink'
import aniStyle from '../index.module.css'

import { appendQueryParams } from 'templates/Pages/HomePps'
import SolixButton from '@components/control/SolixButton'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const Card = ({ data, item, index }) => {
  const { setRelativeLink } = useRelativeLink()
  const cartTypeMap = {
    larger:
      'aspect-w-[1280] aspect-h-[540] md:aspect-w-[342] md:aspect-h-[380]',
    large: 'aspect-w-[737] aspect-h-[460] md:aspect-w-[342] md:aspect-h-[380]',
    bigMedium:
      'aspect-w-[519] aspect-h-[460] md:aspect-w-[342] md:aspect-h-[380]',
    medium: 'aspect-w-[628] aspect-h-[460] md:aspect-w-[342] md:aspect-h-[380]',
    small: 'aspect-w-[302] aspect-h-[460] md:aspect-w-[342]  md:aspect-h-[380]',
  }
  const widthMap = {
    larger: 'col-span-12',
    large: 'col-span-7 md:col-span-12',
    bigMedium: 'col-span-5 md:col-span-12',
    medium: 'col-span-6 md:col-span-12',
    small: 'col-span-3 md:col-span-12',
  }
  const textPositionMap = {
    left: 'min-md:left-0 min-md:top-[50%] min-md:translate-y-[-50%] min-md:text-left  md:text-center md:top-0 md:left-[50%] md:translate-x-[-50%] pl-[80px] md-l:pl-[56px] md:px-[15px] md:pt-[24px] md:w-full',
    top: 'w-full px-[15px] md-xl:px-[10px] pt-[30px] xl:pt-[14px] text-center top-0 left-[50%] translate-x-[-50%]',
  }

  const textFontSizeMap = {
    left: 'text-[40px] leading-[44px] l:text-[20px] l:leading-[24px] l-xl:text-[32px] l-xl:leading-[36px]',
    top: 'text-[28px] leading-[32px] l-xl:text-[24px] l-xl:leading-[28px] l:text-[20px] l:leading-[24px]',
  }

  const textSubtitleMap = {}

  const solixButtonMap = {
    left: 'md:mx-auto ',
    top: 'mx-auto  ',
  }

  const colorMap = {
    dark: 'text-white',
    light: 'text-black',
  }

  return (
    <div
      className={cn(
        'relative cursor-pointer overflow-hidden rounded-[10px]',
        widthMap[item?.cardType] || widthMap['larger'],
        aniStyle?.card_parent
      )}
    >
      <Picture
        className={cn(
          aniStyle?.card_parent_img,
          cartTypeMap[item?.cardType] || cartTypeMap['larger']
        )}
        source={`${item?.img}, ${item?.mbImg} 768`}
        imgClassName="w-full h-full object-cover"
      ></Picture>
      <div
        className={cn(
          'absolute left-0 top-0',
          textPositionMap[item?.txtPosition || 'top']
        )}
      >
        <Text
          className={cn(
            'font-semibold',
            textFontSizeMap[item?.txtPosition || 'top'],
            colorMap[item?.theme] || 'text-white'
          )}
          html={item?.title}
        ></Text>
        {/* <div className="mt-[10px] inline-flex items-center">
          <Text
            className={cn(
              'text-[20px] font-medium text-white l:text-[16px]',
              aniStyle?.card_parent_link
            )}
            html={data?.learnMoreTxt}
          ></Text>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M10.5 8L14.5 12L10.5 16"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </div> */}
        {item?.subtitle && (
          <Text
            className={cn(
              'mt-[8px] min-h-[40px] text-[16px] font-medium leading-[20px] text-white l:text-[12px] l:leading-[16px] xl:min-h-[auto] l-xl:text-[14px] l-xl:leading-[18px]',
              textSubtitleMap[item?.txtPosition || 'top'],
              colorMap[item?.theme] || 'text-white'
            )}
            html={item?.subtitle}
          ></Text>
        )}
        <SolixButton
          className={cn(
            ' mt-[18px] !h-[46px] !w-[186px] !leading-[46px] md:mt-[16px] md:!h-[32px] md:!w-[126px] md:!leading-[32px] xl:mt-[10px] l-xl:!h-[40px] l-xl:!w-[160px] l-xl:!leading-[40px] md-l:!h-[36px] md-l:!w-[140px] md-l:!leading-[36px]',
            solixButtonMap[item?.txtPosition || 'top']
          )}
          variant="link"
          href={appendQueryParams(
            setRelativeLink({ link: item?.learnMoreHref }),
            `ref=pps_seriesBanner${index + 1}`
          )}
        >
          {data?.learnMoreTxt}
        </SolixButton>
      </div>
      {/* <BannerLink
        to={appendQueryParams(
          item?.learnMoreHref,
          `ref=pps_seriesBanner${index + 1}`
        )}
      ></BannerLink> */}
    </div>
  )
}
function SceneGuide({ data, id }) {
  const { setRelativeLink } = useRelativeLink()
  return (
    <div className="layer bg-[#F5F5F7] pt-[120px] md:pt-[72px]">
      <div className="content" id={id}>
        {/* start_ai_generated */}
        <SectionTitle txt={data?.title}></SectionTitle>
        {/* end_ai_generated */}
        {data?.list && (
          <div className="mt-[48px] grid grid-cols-12 gap-[24px] md:gap-[16px] xl:mt-[40px] md-xl:gap-[12px]">
            {/* start_ai_generated */}
            {data?.list?.map((item, index) => {
              return (
                <Card
                  data={data}
                  index={index}
                  item={item}
                  key={index + item.img}
                ></Card>
              )
            })}
            {/* end_ai_generated */}
          </div>
        )}
        {data?.helpMe && (
          <div className="mt-[32px] flex items-center justify-center text-center">
            <a
              href={appendQueryParams(
                setRelativeLink({ link: data?.helpMeHref }),
                'ref=pps_seriesBanner'
              )}
              className="  text-[20px] font-medium text-black hover:underline l:text-[16px]"
              dangerouslySetInnerHTML={{
                __html: data?.helpMe,
              }}
            ></a>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10.5 8L14.5 12L10.5 16"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}

export default SceneGuide
