import { useState } from 'react'
import { Picture, Text } from '@components/ui'
import { cn, rmEmptyKeys } from '@components/helper'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './index.module.scss'
import ProgressBar from '../ProgressBar'

const Index = ({ data, id, pageHandle, abtest }) => {
  const { setRelativeLink } = useRelativeLink()
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <section
      id={id}
      className={cn(
        'layer relative overflow-hidden bg-[#F3F4F5] py-[160px] md:py-[80px]',
        {
          ['pt-0 md:pt-0']: data?.delPaddingTop,
          ['pb-0 md:pb-0']: data?.delPaddingBottom,
        }
      )}
      style={rmEmptyKeys({ background: data?.bgColor })}
    >
      <div className="content !max-w-[1410px]">
        {data?.title && (
          <Text
            html={data.title}
            className={cn(
              'mb-[50px] text-[56px] font-[700] uppercase leading-[1.2] text-[#000] md:mb-[28px] md:text-[32px]',
              {
                '!text-center': abtest,
              }
            )}
          />
        )}

        <ul className={s.col}>
          {data?.list?.map((item, index) => (
            <li
              key={index}
              className={cn(s.itemBox, {
                [s.activeItem]: activeIndex === index,
              })}
              onMouseEnter={() => setActiveIndex(index)}
            >
              <ChevronRightIcon className={s.arrow} />

              <div className={cn('absolute right-0 top-0 h-full w-full')}>
                <Picture
                  source={`${item?.img_b || item?.img}, ${
                    item?.mobImg_b || item?.img_b || item?.mobImg || item?.img
                  } 768`}
                  className={s.image}
                  style={{
                    '--objectPosition':
                      item?.styles?.objectPosition || '100% 100%',
                    '--mobObjectPosition':
                      item?.styles?.mobObjectPosition || '100% 100%',
                  }}
                />
              </div>
              <div className={s.mask}></div>
              <div className={cn(s.absoluteBox)}>
                <div className={s.contentWrap}>
                  <Text
                    html={item?.title_b || item.title}
                    className={s.itemTitle}
                  />
                  <div className={s.descWrap}>
                    <Text
                      html={item?.desc_b || item?.desc}
                      className={s.desc}
                    />
                    <a
                      className={s.learnMore}
                      href={`${setRelativeLink({ link: item?.link })}?ref=${
                        data?.ref ? data?.ref + (index + 1) : pageHandle
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: item?.learnMore_b || item?.learnMore,
                      }}
                    />
                  </div>
                </div>
              </div>
              <ProgressBar s={{ progressBarBox: s.progressBarBox }} />
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Index
