import { Text } from '@components/ui'
import { cn, rmEmptyKeys } from '@components/helper'

import s from './index.module.css'

const Index = ({ id, data }) => {
  return (
    <section
      id={id}
      className={cn('layer bg-[#fff] py-[120px] md:py-[60px] md:pb-0', {
        ['pt-0 md:pt-0']: data?.delTopPadding,
        ['pb-0 md:pb-0']: data?.delBottomPadding,
      })}
      style={rmEmptyKeys({ background: data?.bgColor })}
    >
      <div
        className={cn('content leading-[1.2]', {
          ['!max-w-[1410px]']: data?.compatible,
        })}
      >
        {data.title && (
          <Text
            html={data?.title}
            className={cn(
              'text-center text-[48px] font-[600] text-[#000] md:text-left md:text-[40px]',
              {
                ['text-left text-[56px] font-[700] uppercase md:text-[32px]']:
                  data?.compatible,
              }
            )}
          />
        )}
        {data?.desc && (
          <Text
            html={data?.desc}
            className={cn(
              'mt-[10px] text-center text-[24px] font-medium text-[#000] md:mt-[10px] md:text-left md:text-[18px]',
              { ['text-left text-[24px] md:text-[18px]']: data?.compatible },
              s.descLink
            )}
          />
        )}

        <div className="relative">
          <video
            className="relative w-full min-md:hidden"
            poster={data?.mobPosterUrl || data?.posterUrl}
            src={data?.mobVideoUrl}
            playsInline
            muted
            loop
            autoPlay
          />
          <video
            className={'relative h-full w-full object-cover md:hidden'}
            poster={data?.posterUrl || ''}
            src={data?.videoUrl}
            playsInline
            loop
            muted
            autoPlay
          />
          <ul className={s.col}>
            {data?.list?.map((item, index) => (
              <li key={index} className="flex flex-col gap-[15px] md:gap-[6px]">
                {item?.title && (
                  <Text
                    html={item?.title}
                    className={cn(
                      'text-[24px] font-[700] text-[#000] md:text-[16px]',
                      { ['uppercase']: data?.compatible }
                    )}
                  />
                )}
                {item?.desc && <Text html={item?.desc} className={s.colDesc} />}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Index
