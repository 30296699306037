/* eslint-disable react-hooks/rules-of-hooks */
import { Picture } from '@components/ui'
import SectionTitle from '@components/layout/SectionTitle'
import styles from './Advantage.module.css'
import cn from 'classnames'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'
import Button from '@components/control/Button'
import SolixButton from '@components/control/SolixButton'
import { useRelativeLink } from '@commerce/product/use-relative-link'
const Advantage = ({ data, pageType, index }) => {
  const { setRelativeLink } = useRelativeLink()
  const refAdvantage = useRef(null)
  let itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: {
      x: '-120%',
      transition: { duration: 0.3 },
    },
  }
  const { scrollYProgress } = useScroll({
    target: refAdvantage,
    offset: ['start end', 'end center'],
  })
  const ref = useRef(null)
  const { scrollXProgress } = useScroll({
    container: ref,
  })
  return (
    <div className={cn(styles.root, 'overflow-hidden')}>
      {data?.lastHide && (
        <style jsx global>
          {`
            @media (max-width: 767px) {
              .${styles.advantage_box}:last-child {
                display: none;
              }
            }
          `}
        </style>
      )}
      {data?.title && (
        <SectionTitle
          subTitle={data?.subTitle}
          title={data?.title}
          className={styles.title_box}
          cns={{
            subTitle: data.title_left ? styles.subTitle_left : styles.subTitle,
          }}
          jumpOver={data?.jumpOver}
          blogLink={data?.blogLink}
        />
      )}

      <section
        className={cn(
          styles.advantage_layer,
          { [styles.advantage_layer_padding]: data?.haspadding },
          { [styles.advantage_layer_mobIsSwiper]: data?.mobIsSwiper },
          'layer'
        )}
      >
        <motion.ul
          ref={ref}
          layout
          className={cn(styles.advantage_content, 'content', {
            [styles.advantage_content_flex]: data?.mobIsSwiper,
          })}
        >
          {data?.content &&
            data?.content.map((v, i) => {
              const link = `${setRelativeLink({
                link: v?.link,
              })}?ref=homePageBanner${
                pageType === 'powerStation' || pageType === 'hes' ? '_pps' : ''
              }`
              return (
                <motion.li
                  layout
                  key={`advantage-${i}`}
                  ref={refAdvantage}
                  className={cn(styles.advantage_box, {
                    [styles.advantage_onlyOne]: v.onlyOne,
                    [styles.advantage_onlyOne_left]: v?.imgLeft,
                    [styles.advantage_boxWidth]: data.mobIsSwiper,
                    ['!cursor-auto']: !v?.learnMore,
                    // ['md:!overflow-auto']: data.mobIsSwiper && v?.imgLeft,
                  })}
                  onClick={() => {
                    v?.learnMore && (window.location.href = link)
                  }}
                  initial="hidden"
                  whileInView="show"
                  viewport={{ once: true, amount: 0.6 }}
                  variants={{
                    show: {
                      transition: {
                        duration: 0.5,
                        staggerChildren: 0.05,
                      },
                    },
                    hidden: {
                      transition: {
                        duration: 0.5,
                      },
                    },
                  }}
                >
                  <motion.div
                    layout
                    className={cn(styles.advantage_flex, 'flex', {
                      [styles.advantage_flex_onlyOne]: v.onlyOne,
                      [styles.advantage_flex_onlyOne_left]: v?.imgLeft,
                      ['md:!hidden md:!overflow-auto']: data.mobIsSwiper,
                    })}
                  >
                    <motion.div
                      layout
                      className={cn(styles.advantage_textAll, {
                        [styles.advantage_textAllOnlyOne]: v.onlyOne,
                        [styles.advantage_textAllOnlyOne_left]: v?.imgLeft,
                        [styles.advantage_textAll_mobIsSwiper]:
                          data.mobIsSwiper,
                        [styles.advantage_textAll_pps]:
                          pageType === 'powerStation' || pageType === 'hes',
                      })}
                    >
                      <motion.div
                        layout
                        className={cn(styles.advantage_title_box, {
                          [styles.advantage_titleOnlyOne_box]: v.onlyOne,
                        })}
                      >
                        <motion.div className="md:px-[24px]">
                          <motion.div className="md_content_grid">
                            {v?.subTitle && (
                              <motion.p
                                variants={itemVariants}
                                layout
                                className={styles.advantage_subTitle}
                                dangerouslySetInnerHTML={{
                                  __html: v?.subTitle,
                                }}
                              />
                            )}
                            <motion.div
                              className={cn(
                                styles.advantage_title_grid,
                                'md_content_grid',
                                {
                                  [styles.advantage_titleOnlyOne_grid]:
                                    v.onlyOne,
                                }
                              )}
                            >
                              {v?.title &&
                                v?.title.map((item, index) => (
                                  <motion.p
                                    layout
                                    key={`advantagetitle-${index}`}
                                    variants={itemVariants}
                                    className={cn(styles.advantage_title, {
                                      [styles.advantage_titleOnlyOne]:
                                        v.onlyOne,
                                      [styles.advantage_titleOnlyOne_pps]:
                                        pageType === 'powerStation' ||
                                        pageType === 'hes',
                                    })}
                                    dangerouslySetInnerHTML={{
                                      __html: item?.title,
                                    }}
                                  />
                                ))}
                            </motion.div>
                            {v?.text && (
                              <motion.p
                                variants={itemVariants}
                                layout
                                className={cn(styles.advantage_text, {
                                  [styles.advantage_text_pps]:
                                    pageType === 'powerStation' ||
                                    pageType === 'hes',
                                })}
                                dangerouslySetInnerHTML={{
                                  __html: v?.text,
                                }}
                              />
                            )}
                            <motion.div
                              variants={itemVariants}
                              className={cn(
                                styles.advantage_a_box,
                                {
                                  [styles.advantage_a_box_onlyOne]: v.onlyOne,
                                },
                                'w-full'
                              )}
                              layout
                            >
                              {v?.learnMore && (
                                <>
                                  {data.isBtn ? (
                                    <>
                                      {pageType === 'powerStation' ||
                                      pageType === 'hes' ? (
                                        <SolixButton className={cn()}>
                                          <motion.a
                                            className={cn('block')}
                                            layout
                                            href={link}
                                            target="_self"
                                            dangerouslySetInnerHTML={{
                                              __html: v?.learnMore,
                                            }}
                                          />
                                        </SolixButton>
                                      ) : (
                                        <Button
                                          className={cn(
                                            styles.advantage_a_box,
                                            styles.btn
                                          )}
                                        >
                                          <motion.a
                                            className={cn('block')}
                                            layout
                                            href={link}
                                            target="_self"
                                            dangerouslySetInnerHTML={{
                                              __html: v?.learnMore,
                                            }}
                                          />
                                        </Button>
                                      )}
                                    </>
                                  ) : (
                                    <motion.p
                                      layout
                                      className={cn(styles.advantage_a_box, {})}
                                    >
                                      <motion.a
                                        className={styles.advantage_a}
                                        layout
                                        href={link}
                                        target="_self"
                                        dangerouslySetInnerHTML={{
                                          __html: v?.learnMore,
                                        }}
                                      />
                                    </motion.p>
                                  )}
                                </>
                              )}
                            </motion.div>
                          </motion.div>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                    <motion.div className={styles.advantage_img_box}>
                      <Picture
                        source={`${v?.img},${v?.img_mob} 768`}
                        className={cn(
                          styles.advantage_img,
                          { ['aspect-h-[370] aspect-w-[848]']: v.onlyOne },
                          { ['aspect-h-[240] aspect-w-[416]']: !v.onlyOne },
                          {
                            ['md:aspect-h-[494] md:aspect-w-[768]']:
                              pageType === 'powerStation' || pageType === 'hes',
                          },
                          {
                            ['md:aspect-h-[382] md:aspect-w-[768]']:
                              pageType === 'powerStation' || pageType === 'hes',
                          }
                        )}
                      />
                    </motion.div>
                  </motion.div>
                  {data?.mobIsSwiper && (
                    <motion.div
                      layout
                      className={cn(styles.advantage_flex, {
                        [styles.advantage_flex_onlyOne]: v.onlyOne,
                        [styles.advantage_flex_onlyOne_left]: v?.imgLeft,
                        ['!hidden md:!flex ']: data.mobIsSwiper,
                      })}
                    >
                      <motion.div
                        layout
                        className={cn(styles.advantage_textAll, {
                          [styles.advantage_textAllOnlyOne]: v.onlyOne,
                          [styles.advantage_textAllOnlyOne_left]: v?.imgLeft,
                          [styles.advantage_textAll_mobIsSwiper]:
                            data.mobIsSwiper,
                          [styles.advantage_textAll_pps]:
                            pageType === 'powerStation' || pageType === 'hes',
                        })}
                      >
                        <motion.div
                          layout
                          className={cn(styles.advantage_title_box, {
                            [styles.advantage_titleOnlyOne_box]: v.onlyOne,
                          })}
                        >
                          <motion.div className="md_layer_grid">
                            <motion.div className="md_content_grid">
                              {v?.subTitle && (
                                <motion.p
                                  layout
                                  className={styles.advantage_subTitle}
                                  dangerouslySetInnerHTML={{
                                    __html: v?.subTitle,
                                  }}
                                />
                              )}
                              <motion.div
                                className={cn(
                                  styles.advantage_title_grid,
                                  'md_content_grid',
                                  {
                                    [styles.advantage_titleOnlyOne_grid]:
                                      v.onlyOne,
                                  }
                                )}
                              >
                                {v?.title &&
                                  v?.title.map((item, index) => (
                                    <motion.p
                                      layout
                                      key={`advantagetitle-${index}`}
                                      className={cn(styles.advantage_title, {
                                        [styles.advantage_titleOnlyOne]:
                                          v.onlyOne,
                                      })}
                                      dangerouslySetInnerHTML={{
                                        __html: item?.title,
                                      }}
                                    />
                                  ))}
                              </motion.div>
                              {v?.text && (
                                <motion.p
                                  layout
                                  className={styles.advantage_text}
                                  dangerouslySetInnerHTML={{
                                    __html: v?.text,
                                  }}
                                />
                              )}
                              {data.isBtn ? (
                                <Button
                                  className={cn(
                                    styles.advantage_a_box,
                                    {
                                      [styles.advantage_a_box_onlyOne]:
                                        v.onlyOne,
                                    },
                                    styles.btn
                                  )}
                                >
                                  <motion.a
                                    className={cn('block')}
                                    layout
                                    href={link}
                                    target="_self"
                                    dangerouslySetInnerHTML={{
                                      __html: v?.learnMore,
                                    }}
                                  />
                                </Button>
                              ) : (
                                <motion.p
                                  layout
                                  className={cn(styles.advantage_a_box, {
                                    [styles.advantage_a_box_onlyOne]: v.onlyOne,
                                  })}
                                >
                                  <motion.a
                                    className={styles.advantage_a}
                                    layout
                                    href={link}
                                    target="_self"
                                    dangerouslySetInnerHTML={{
                                      __html: v?.learnMore,
                                    }}
                                  />
                                </motion.p>
                              )}
                            </motion.div>
                          </motion.div>
                        </motion.div>
                      </motion.div>
                      <motion.div className={styles.advantage_img_box}>
                        <Picture
                          source={`${v?.img},${v?.img_mob} 768`}
                          className={cn(
                            styles.advantage_img,
                            { ['aspect-h-[370] aspect-w-[848]']: v.onlyOne },
                            { ['aspect-h-[240] aspect-w-[416]']: !v.onlyOne },
                            'md:aspect-w-[768] ',
                            'md:aspect-h-[382]'
                          )}
                        />
                      </motion.div>
                    </motion.div>
                  )}
                </motion.li>
              )
            })}
        </motion.ul>
        {data?.mobIsSwiper && (
          <div className=" relative col-span-layer-12-full m-auto mt-[25px] hidden h-[4px] w-[100px] rounded-[4px] bg-[#E5E5E5] md:block">
            <motion.div
              style={{
                width: useTransform(scrollXProgress, [0, 1], ['0%', '100%']),
              }}
              className="absolute  left-0 top-0 h-full w-full rounded-l-[10px] rounded-r-[10px] bg-[#00BEFA]"
            ></motion.div>
          </div>
        )}
      </section>
    </div>
  )
}
export default Advantage
