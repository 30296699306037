import React, {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  ReactNode,
  CSSProperties,
  FC,
  forwardRef,
  Children,
  MutableRefObject,
  RefAttributes,
} from 'react'

type MarqueeProps = {
  /**
   * @description Inline style for the container div
   * @type {CSSProperties}
   * @default {}
   */
  style?: CSSProperties
  /**
   * @description Class name to style the container div
   * @type {string}
   * @default ""
   */
  className?: string
  /**
   * @description Whether to automatically fill blank space in the marquee with copies of the children or not
   * @type {boolean}
   * @default false
   */
  autoFill?: boolean
  /**
   * @description Whether to play or pause the marquee
   * @type {boolean}
   * @default true
   */
  play?: boolean
  /**
   * @description Whether to pause the marquee when hovered
   * @type {boolean}
   * @default false
   */

  /**
   * @description The direction the marquee is sliding
   * @type {"left" | "right" | "up" | "down"}
   * @default "left"
   */
  direction?: 'left' | 'right' | 'up' | 'down'
  /**
   * @description Speed calculated as pixels/second
   * @type {number}
   * @default 50
   */
  speed?: number
  /**
   * @description Duration to delay the animation after render, in seconds
   * @type {number}
   * @default 0
   */
  hoverSpeed?: number

  /**
   * @description A callback for when the marquee finishes scrolling and stops. Only calls if loop is non-zero.
   * @type {() => void}
   * @default null
   */
  onFinish?: () => void
  /**
   * @description A callback for when the marquee finishes a loop. Does not call if maximum loops are reached (use onFinish instead).
   * @type {() => void}
   * @default null
   */
  onCycleComplete?: () => void
  /**
   * @description: A callback function that is invoked once the marquee has finished mounting. It can be utilized to recalculate the page size, if necessary.
   * @type {() => void}
   * @default null
   */
  onMount?: () => void
  /**
   * @description The children rendered inside the marquee
   * @type {ReactNode}
   * @default null
   */
  children?: ReactNode
} & RefAttributes<HTMLDivElement>

const Marquee: FC<MarqueeProps> = forwardRef(function Marquee(
  {
    style = {},
    className = '',
    autoFill = false,
    play = true,
    direction = 'left',
    speed = 50,
    hoverSpeed = 0,
    onFinish,
    onCycleComplete,
    onMount,
    children,
  },
  ref
) {
  // React Hooks
  const [containerWidth, setContainerWidth] = useState(0)
  const [marqueeWidth, setMarqueeWidth] = useState(0)
  const [multiplier, setMultiplier] = useState(1)
  const [isMounted, setIsMounted] = useState(false)
  const rootRef = useRef<HTMLDivElement>(null)
  const containerRef = (ref as MutableRefObject<HTMLDivElement>) || rootRef
  const marqueeRef = useRef<HTMLDivElement>(null)
  const [currentSpeed, setCurrentSpeed] = useState(speed)
  const requestRef = useRef<number>()
  const lastTimeRef = useRef<number>()
  const [position, setPosition] = useState(0)

  // Calculate width of container and marquee and set multiplier
  // start_ai_generated
  const calculateWidth = useCallback(() => {
    if (marqueeRef.current && containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect()
      const marqueeRect = marqueeRef.current.getBoundingClientRect()
      let containerWidth = containerRect.width
      let marqueeWidth = marqueeRect.width

      // Swap width and height if direction is up or down
      if (direction === 'up' || direction === 'down') {
        containerWidth = containerRect.height
        marqueeWidth = marqueeRect.height
      }

      if (autoFill && containerWidth && marqueeWidth) {
        setMultiplier(
          marqueeWidth < containerWidth
            ? Math.ceil(containerWidth / marqueeWidth)
            : 1
        )
      } else {
        setMultiplier(1)
      }

      setContainerWidth(containerWidth)
      setMarqueeWidth(marqueeWidth)
    }
  }, [autoFill, containerRef, direction])
  // end_ai_generated

  // Calculate width and multiplier on mount and on window resize
  useEffect(() => {
    if (!isMounted) return

    calculateWidth()
    if (marqueeRef.current && containerRef.current) {
      const resizeObserver = new ResizeObserver(() => calculateWidth())
      resizeObserver.observe(containerRef.current)
      resizeObserver.observe(marqueeRef.current)
      return () => {
        if (!resizeObserver) return
        resizeObserver.disconnect()
      }
    }
  }, [calculateWidth, containerRef, isMounted])

  // Recalculate width when children change
  useEffect(() => {
    calculateWidth()
  }, [calculateWidth, children])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  // Runs the onMount callback, if it is a function, when Marquee is mounted.
  useEffect(() => {
    if (typeof onMount === 'function') {
      onMount()
    }
  }, [])

  const marqueeStyle = useMemo(
    () => ({
      transform: `translateX(${position}px)`,
    }),
    [position]
  )

  const childStyle = useMemo(
    () => ({
      ['--transform' as string]:
        direction === 'up'
          ? 'rotate(90deg)'
          : direction === 'down'
          ? 'rotate(-90deg)'
          : 'none',
    }),
    [direction]
  )

  // start_ai_generated
  const animate = (time: number) => {
    if (lastTimeRef.current !== undefined) {
      const deltaTime = time - lastTimeRef.current
      const distance = (currentSpeed * deltaTime) / 1000
      setPosition((prevPosition) => {
        const newPosition = prevPosition - distance
        if (newPosition < -marqueeWidth) {
          return 0 // 重置位置
        }
        return newPosition
      })
    }
    lastTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
  }
  // end_ai_generated

  // Render {multiplier} number of children
  const multiplyChildren = useCallback(
    (multiplier: number) => {
      return [
        ...Array(
          Number.isFinite(multiplier) && multiplier >= 0 ? multiplier : 0
        ),
      ].map((_, i) => (
        <Fragment key={i}>
          {Children.map(children, (child) => {
            return (
              <div style={childStyle} className="rfm-child">
                {child}
              </div>
            )
          })}
        </Fragment>
      ))
    },
    [childStyle, children]
  )

  useEffect(() => {
    if (play) {
      requestRef.current = requestAnimationFrame(animate)
    }

    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
    }
  }, [play, currentSpeed, marqueeWidth])

  const handleMouseOver = () => {
    setCurrentSpeed(hoverSpeed > 0 ? hoverSpeed : speed * 0.5)
  }

  const handleMouseOut = () => {
    setCurrentSpeed(speed)
  }

  return !isMounted ? null : (
    <>
      <style jsx>
        {`
          .rfm-marquee-container {
            overflow-x: hidden;
            display: flex;
            flex-direction: row;
            position: relative;
            width: var(--width);
            transform: var(--transform);
          }

          .rfm-marquee {
            flex: 0 0 auto;
            min-width: var(--min-width);
            z-index: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          .rfm-initial-child-container {
            flex: 0 0 auto;
            display: flex;
            min-width: auto;
            flex-direction: row;
            align-items: center;
          }
          .rfm-child {
            transform: var(--transform);
          }
        `}
      </style>
      <div
        ref={containerRef}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={'rfm-marquee-container ' + className}
      >
        <div
          className="rfm-marquee"
          style={marqueeStyle}
          onAnimationIteration={onCycleComplete}
          onAnimationEnd={onFinish}
        >
          <div className="rfm-initial-child-container" ref={marqueeRef}>
            {Children.map(children, (child) => {
              return (
                <div style={childStyle} className="rfm-child">
                  {child}
                </div>
              )
            })}
          </div>
          {multiplyChildren(multiplier - 1)}
        </div>
        <div className="rfm-marquee" style={marqueeStyle}>
          {multiplyChildren(multiplier)}
        </div>
      </div>
    </>
  )
})

export default Marquee
