import { Text, Picture } from '@components/ui'
import SectionTitle from '../SectionTitle'
import Button from '@components/control/Button'
import cn from 'classnames'
import aniStyle from '../index.module.css'
import s from './index.module.css'
import { appendQueryParams } from 'templates/Pages/HomePps'
import BannerLink from '@components/common/BannerLink'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import { Arrow } from '../Arrow'
import { useState } from 'react'
const Card = ({ data, item, index }) => {
  return (
    <div
      className={cn(
        'aspect-h-[460] aspect-w-[628] relative w-full overflow-hidden rounded-[10px] xl:aspect-h-[460] xl:aspect-w-[424]',
        aniStyle?.card_parent
      )}
    >
      <Picture
        source={`${item?.img}, ${item?.mbImg} 1024`}
        className={cn('h-full w-full', aniStyle?.card_parent_img)}
        imgClassName="w-full h-full object-cover"
      ></Picture>
      <div className="absolute left-0 top-0 flex h-full w-full flex-col items-center px-[12px] pt-[48px] l:pt-[32px]">
        {item?.title && (
          <Text
            html={item?.title}
            className="text-[40px] font-semibold leading-[44px] text-white xl:text-[24px] xl:leading-[28px]"
          ></Text>
        )}
        {item?.subtitle && (
          <Text
            className=" mt-[8px] text-[17px] font-medium leading-[21px] text-white l:text-[14px] l:leading-[16px]"
            html={item?.subtitle}
          ></Text>
        )}

        {item?.learnMoreHref && (
          // <div className="mt-[16px] w-[182px] md:w-[128px] md-xl:w-[160px]">
          //   <Button
          //     href={appendQueryParams(
          //       item?.learnMoreHref,
          //       'ref=homePageBanner_pps'
          //     )}
          //     className=" !border-white !text-white md:!h-[32px] md:!leading-[32px]"
          //     border={true}
          //     variant="link"
          //     cns={{
          //       mask: 'hidden',
          //     }}
          //   >
          //     {data?.learnMoreTxt}
          //   </Button>
          // </div>
          <div className="mt-[10px] inline-flex items-center">
            <Text
              className={cn(
                'text-[20px] font-medium text-white l:text-[16px]',
                aniStyle?.card_parent_link
              )}
              html={data?.learnMoreTxt}
            ></Text>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10.5 8L14.5 12L10.5 16"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
        )}
      </div>
      <BannerLink
        to={appendQueryParams(
          item?.learnMoreHref,
          `ref=homePageBanner_pps${index + 1}`
        )}
      ></BannerLink>
    </div>
  )
}
function BrandStory({ data, id }) {
  const [isStart, setStart] = useState(true)
  const [isEnd, setEnd] = useState(false)
  return (
    <div className="layer overflow-hidden py-[120px] md:py-[72px]">
      <div className="content" id={id}>
        {/* start_ai_generated */}
        <SectionTitle txt={data?.title}></SectionTitle>
        {/* end_ai_generated */}
        {data?.list?.length > 2 ? (
          <div className="mt-[48px] w-screen xl:mt-[32px]">
            <div className={cn(s.custom_max_w, 'relative')}>
              <Swiper
                className="!overflow-visible"
                navigation={{
                  nextEl: `#${id} .brandstory-custom-swiper-button-next`,
                  prevEl: `#${id} .brandstory-custom-swiper-button-prev`,
                }}
                modules={[Navigation]}
                onProgress={(swiper) => {
                  setStart(swiper.isBeginning)
                  setEnd(swiper.isEnd)
                }}
                breakpoints={{
                  0: {
                    spaceBetween: '10px',
                    slidesPerView: 1.1,
                  },
                  768: {
                    spaceBetween: '12px',
                    slidesPerView: 2,
                  },
                  1024: {
                    spaceBetween: '16px',
                    slidesPerView: 2,
                  },
                  1440: {
                    spaceBetween: '24px',
                    slidesPerView: 2,
                  },
                }}
              >
                {data?.list?.map((item, index) => {
                  return (
                    <SwiperSlide key={index + item.img}>
                      <Card
                        index={index}
                        data={data}
                        item={item}
                        key={index + item.img}
                      ></Card>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
              <Arrow
                isStart={isStart}
                isEnd={isEnd}
                prevClass="brandstory-custom-swiper-button-prev"
                nextClass="brandstory-custom-swiper-button-next"
              ></Arrow>
            </div>
          </div>
        ) : (
          <div className="mt-[48px] grid grid-cols-12 gap-[24px] xl:mt-[32px] xl:gap-[16px]">
            {data?.list &&
              data?.list?.map((item, index) => {
                return (
                  <div
                    className="col-span-6  md:col-span-12 "
                    key={index + item.img}
                  >
                    <Card
                      index={index}
                      data={data}
                      item={item}
                      key={index + item.img}
                    ></Card>
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </div>
  )
}

export default BrandStory
