import { useState, useEffect } from 'react'
import { Picture, Text } from '@components/ui'
import { cn, rmEmptyKeys } from '@components/helper'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './index.module.scss'
import ProgressBar from '../ProgressBar'

import BannerLink from '@components/common/BannerLink'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'

const Index = ({ data, id, pageHandle, abtest, children }) => {
  const { homeSolutions, coreCompetitiveness } = data

  const { setRelativeLink } = useRelativeLink()
  const [activeIndex, setActiveIndex] = useState(0)
  const [miniHeight, setMiniHeight] = useState(false)

  const settings = {
    speed: 400,
    loop: coreCompetitiveness?.loop || false,
    spaceBetween: 12,
    autoplay: coreCompetitiveness?.autoplay || false,
    modules: [Navigation, Autoplay, Pagination],
    pagination:
      coreCompetitiveness?.list?.length > 1
        ? {
            clickable: true,
            bulletActiveClass: s.activeBullet,
            bulletClass: s.bullet,
          }
        : false,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
      },
      768: {
        slidesPerView: 1.1,
      },
    },
    slideToClickedSlide: true,
  }

  const resizeWindow = () => {
    const windowHeightWithoutScrollbar =
      document.documentElement.clientHeight || document.body.clientHeight || 0
    if (windowHeightWithoutScrollbar <= 738) {
      setMiniHeight(true)
    } else {
      setMiniHeight(false)
    }
  }

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  return (
    <section
      id={id}
      className={cn('relative', s.wrap)}
      style={rmEmptyKeys({ background: data?.bgColor })}
    >
      {homeSolutions && (
        <div
          id="aniHomeSolutions"
          className={cn(
            'layer overflow-hidden bg-[#F3F4F5]  py-[160px] md:relative  md:!top-0 md:py-[80px] xxl:top-[-60px]',
            s.homeSolutionsWrap,
            {
              ['pt-0 md:pt-0']: homeSolutions?.delPaddingTop,
              ['pb-0 md:pb-0']: homeSolutions?.delPaddingBottom,
              // 'sticky top-0': !abtest,
            }
          )}
          style={rmEmptyKeys({ background: homeSolutions?.bgColor })}
        >
          <div className="content !max-w-[1410px]">
            {homeSolutions?.title && (
              <Text
                html={homeSolutions.title}
                className={cn(
                  'mb-[50px] text-[56px] font-[700] uppercase leading-[1.2] text-[#000] md:mb-[28px] md:text-[32px]',
                  { [s.miniTitle]: miniHeight, '!text-center': abtest }
                )}
              />
            )}

            <ul className={cn(s.col, { [s.miniCol]: miniHeight })}>
              {homeSolutions?.list?.map((item, index) => (
                <li
                  key={index}
                  className={cn(s.itemBox, {
                    [s.activeItem]: activeIndex === index,
                  })}
                  onMouseEnter={() => setActiveIndex(index)}
                >
                  <ChevronRightIcon className={s.arrow} />

                  <div className={cn('absolute right-0 top-0 h-full w-full')}>
                    <Picture
                      source={`${item?.img_b || item?.img}, ${
                        item?.mobImg_b ||
                        item?.img_b ||
                        item?.mobImg ||
                        item?.img
                      } 768`}
                      className={s.image}
                      style={{
                        '--objectPosition':
                          item?.styles?.objectPosition || '100% 100%',
                        '--mobObjectPosition':
                          item?.styles?.mobObjectPosition || '100% 100%',
                      }}
                    />
                  </div>
                  <div className={s.mask}></div>
                  <div className={cn(s.absoluteBox)}>
                    <div className={s.contentWrap}>
                      <Text
                        html={item?.title_b || item.title}
                        className={s.itemTitle}
                      />
                      <div className={s.descWrap}>
                        <Text
                          html={item?.desc_b || item?.desc || ''}
                          className={s.desc}
                        />
                        <a
                          className={s.learnMore}
                          href={`${setRelativeLink({ link: item?.link })}?ref=${
                            homeSolutions?.ref
                              ? homeSolutions?.ref + (index + 1)
                              : pageHandle
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: item?.learnMore_b || item?.learnMore,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <ProgressBar s={{ progressBarBox: s.progressBarBox }} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      {abtest && children && (
        <div
          className="sticky top-[80px] bg-[#F3F4F5] pb-[160px] md:static md:pb-20 xxl:top-[60px]"
          id={'BestChoice_B'}
        >
          {children}
        </div>
      )}

      <div
        id="aniCoreCompetitivenessWrap"
        className={cn(
          'layer relative overflow-hidden bg-[#000] py-[140px] leading-[1.3] md:py-[65px] md:pb-[90px] md:pr-0 md:leading-[1.2]',
          s.coreCompetitivenessWrap,
          {
            ['pt-0 md:pt-0']: coreCompetitiveness?.delPaddingTop,
            ['pb-0 md:pb-0']: coreCompetitiveness?.delPaddingBottom,
          }
        )}
        style={rmEmptyKeys({ background: coreCompetitiveness?.bgColor })}
      >
        <div className="content !max-w-[1410px]">
          {coreCompetitiveness?.title && (
            <Text
              html={coreCompetitiveness.title}
              className={cn(
                'mb-[50px] text-[56px] font-[700] uppercase text-[#fff] md:mb-[28px] md:text-[32px]',
                {
                  '!text-center': abtest,
                }
              )}
            />
          )}

          {coreCompetitiveness?.list?.length && (
            <ul className={cn(s.col)}>
              <li>
                {coreCompetitiveness?.list?.slice(0, 2).map((item, index) => (
                  <Row
                    item={item}
                    data={coreCompetitiveness}
                    key={index}
                    index={index == 0 ? 0 : 2}
                    pageHandle={pageHandle}
                    abtest={abtest}
                  />
                ))}
              </li>
              <li>
                {coreCompetitiveness?.list?.slice(2, 4).map((item, index) => (
                  <Row
                    item={item}
                    data={coreCompetitiveness}
                    key={index}
                    index={index == 0 ? 1 : 3}
                    pageHandle={pageHandle}
                    abtest={abtest}
                  />
                ))}
              </li>
            </ul>
          )}

          <div className="min-md:hidden">
            <Swiper {...settings} className={s.swiperBox}>
              {coreCompetitiveness?.list?.map((item, index) => (
                <SwiperSlide key={index} className="relative">
                  <Row
                    item={item}
                    data={coreCompetitiveness}
                    key={index}
                    index={index}
                    pageHandle={pageHandle}
                    abtest={abtest}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <style jsx global>{`
          .${s.coreCompetitivenessWrap} .${s.swiperBox} .swiper-pagination {
            bottom: -40px;
            display: flex;
            gap: 5px;
            justify-content: center;
          }

          @media (max-width: 769px) {
            .${s.coreCompetitivenessWrap} .${s.swiperBox} .swiper-pagination {
              bottom: -30px;
            }
          }
        `}</style>
      </div>
    </section>
  )
}

export default Index

const Row = ({ item, data, pageHandle, index, abtest }) => {
  const { setRelativeLink } = useRelativeLink()

  return (
    <div className={s.hoverBox}>
      <Picture
        source={`${item?.img_b || item?.img}, ${
          item?.mobImg_b || item?.img_b || item?.mobImg || item?.img
        } 768`}
        className={cn('h-full w-full md:aspect-h-[400] md:aspect-w-[335]')}
      />
      <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-end overflow-hidden px-[40px] md:w-auto md:px-[15px]">
        <div className={cn(s.textWrap, 'textWrap')}>
          {item.link && (
            <BannerLink
              to={setRelativeLink({
                link: `${setRelativeLink({ link: item?.link })}?ref=${
                  data?.ref ? data?.ref + (index + 1) : pageHandle
                }`,
              })}
            />
          )}
          <Text
            html={item?.title_b || item.title}
            className="max-w-[362px] text-[30px] font-[700] uppercase text-[#fff] md:max-w-[unset] md:text-[20px]"
          />
          <div className={cn(s.arrowWrap, 'arrowWrap')}>
            <Text
              html={item?.desc_b || item?.desc || ''}
              className={cn(s.colDesc, 'colDesc')}
            />
            {item.link && (
              <Picture
                source={`${data?.arrow}`}
                className={cn(s.arrow, 'arrow')}
              />
            )}
          </div>
        </div>
      </div>
      <ProgressBar
        s={{ progressBarBox: `${s.progressBarBox} progressBarBox` }}
      />
    </div>
  )
}
