import { useState } from 'react'
import { Picture, Text } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import { cn, rmEmptyKeys } from '@components/helper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import s from './index.module.css'
import ProgressBar from '../ProgressBar'
import BannerLink from '@components/common/BannerLink'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Index = ({ id, data, pageHandle }) => {
  const [swiperRef, setSwiperRef] = useState(null)

  const [realIndex, setRealIndex] = useState(0)

  const changeSwiper = (index) => {
    setRealIndex(index)
    if (!!swiperRef) {
      swiperRef?.slideToLoop(index)
    }
  }

  const settings = {
    speed: 400,
    loop: data?.loop || false,
    spaceBetween: 12,
    autoplay: data?.autoplay || false,
    modules: [Navigation, Autoplay, Pagination],
    pagination:
      data?.list?.length > 1
        ? {
            clickable: true,
            bulletActiveClass: s.activeBullet,
            bulletClass: s.bullet,
          }
        : false,
    breakpoints: {
      0: {
        slidesPerView: 1.1,
      },
      768: {
        slidesPerView: 1.1,
      },
    },
    slideToClickedSlide: true,
  }

  return (
    <section
      id={id}
      className={cn(
        'layer relative overflow-hidden bg-[#000] py-[140px] leading-[1.3] md:py-[65px] md:pb-[90px] md:pr-0 md:leading-[1.2]',
        {
          ['pt-0 md:pt-0']: data?.delPaddingTop,
          ['pb-0 md:pb-0']: data?.delPaddingBottom,
        }
      )}
      style={rmEmptyKeys({ background: data?.bgColor })}
    >
      <div className="content !max-w-[1410px]">
        {data?.title && (
          <Text
            html={data.title}
            className="mb-[50px] text-[56px] font-[700] uppercase text-[#fff] md:mb-[28px] md:text-[32px]"
          />
        )}

        {data?.list?.length && (
          <ul className={cn(s.col)}>
            <li>
              {data?.list?.slice(0, 2).map((item, index) => (
                <Row
                  item={item}
                  data={data}
                  key={index}
                  index={index == 0 ? 0 : 2}
                  pageHandle={pageHandle}
                />
              ))}
            </li>
            <li>
              {data?.list?.slice(2, 4).map((item, index) => (
                <Row
                  item={item}
                  data={data}
                  key={index}
                  index={index == 0 ? 1 : 3}
                  pageHandle={pageHandle}
                />
              ))}
            </li>
          </ul>
        )}

        <div className="min-md:hidden">
          <Swiper
            {...settings}
            className={s.swiperBox}
            onSwiper={setSwiperRef}
            onSlideChange={(swiper) => setRealIndex(swiper.realIndex)}
          >
            {data?.list?.map((item, index) => (
              <SwiperSlide key={index} className="relative">
                <Row
                  item={item}
                  data={data}
                  key={index}
                  index={index}
                  pageHandle={pageHandle}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <style jsx global>{`
        #${id} .${s.swiperBox} .swiper-pagination {
          bottom: -40px;
          display: flex;
          gap: 5px;
          justify-content: center;
        }

        @media (max-width: 769px) {
          #${id} .${s.swiperBox} .swiper-pagination {
            bottom: -30px;
          }
        }
      `}</style>
    </section>
  )
}

export default Index

const Row = ({ item, data, pageHandle, index }) => {
  const { setRelativeLink } = useRelativeLink()

  return (
    <div className={s.hoverBox}>
      <Picture
        source={`${item?.img}, ${item?.mobImg || item?.img} 768`}
        className={cn('h-full w-full md:aspect-h-[400] md:aspect-w-[335]')}
      />
      <div className="absolute left-0 top-0 flex h-full w-full flex-col justify-end overflow-hidden px-[40px] md:w-auto md:px-[15px]">
        <div className={cn(s.textWrap, 'textWrap')}>
          {item.link && (
            <BannerLink
              to={setRelativeLink({
                link: `${setRelativeLink({ link: item?.link })}?ref=${
                  data?.ref ? data?.ref + (index + 1) : pageHandle
                }`,
              })}
            />
          )}
          <Text
            html={item.title}
            className="max-w-[362px] text-[30px] font-[700] uppercase text-[#fff] md:max-w-[unset] md:text-[20px]"
          />
          <div className={cn(s.arrowWrap, 'arrowWrap')}>
            <Text html={item.desc} className={cn(s.colDesc, 'colDesc')} />
            {item.link && (
              <Picture
                source={`${data?.arrow}`}
                className={cn(s.arrow, 'arrow')}
              />
            )}
          </div>
        </div>
      </div>
      <ProgressBar
        s={{ progressBarBox: `${s.progressBarBox} progressBarBox` }}
      />
    </div>
  )
}
