/* eslint-disable react-hooks/rules-of-hooks */
import cn from 'classnames'
import { useState, useRef } from 'react'
import { useRouter } from 'next/router'
import { Text, Picture } from '@components/ui'
import { easingTransitions } from '@lib/utils/animate'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import Button from '@components/control/Button'
import SectionTitle from '@components/layout/SectionTitle'
import HotSpot from './HotSpot'

import s from './HotSpots.module.css'
import { once } from 'events'

const HotSpots = ({
  page,
  mode = {},
  data,
  pageType,
  relatedProducts,
  metafields,
  indexList,
}) => {
  const router = useRouter()
  const useParallaH = (value, distance) => {
    return useTransform(value, [0, 1], [0, distance])
  }
  const ref = useRef(null)
  const { scrollXProgress } = useScroll({
    container: ref,
  })

  return (
    <div className={s.root}>
      <SectionTitle subTitle={data?.subTitle} title={data?.title} />
      <div className={cn('layer', { ['md:px-0']: data.mobIsSwiper })}>
        <div className={cn(s.content, 'content', '')}>
          <ul
            ref={ref}
            className={cn(s.spotsList, {
              [s.spotsList_flex]: data.mobIsSwiper,
            })}
          >
            {data?.hotSpotsList?.map((item, i) => {
              return (
                <li
                  key={`hotSpotsList-${item?.bgUrl}-${i}`}
                  className={s.spotBox}
                >
                  <HotSpot
                    relatedProducts={relatedProducts}
                    pageType={pageType}
                    lang={item}
                    indexList={indexList}
                    metafields={metafields}
                    mobIsSwiper={data.mobIsSwiper}
                    index={i}
                    key={`hotSpot-${item?.bgUrl}-${i}`}
                  />
                </li>
              )
            })}
          </ul>
          {data.mobIsSwiper && (
            <div className=" relative m-auto mt-[25px] hidden h-[4px] w-[100px] rounded-[4px] bg-[#E5E5E5] md:mb-[64px] md:block">
              <motion.div
                style={{
                  width: useTransform(scrollXProgress, [0, 1], ['0%', '100%']),
                }}
                className="absolute  left-0 top-0 h-full w-full rounded-l-[10px] rounded-r-[10px] bg-[#00BEFA]"
              ></motion.div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HotSpots
