import { Autoplay } from 'swiper/modules'
import { Picture, Text } from '@components/ui'
import { Swiper, SwiperSlide } from 'swiper/react'
import React, { useState, useEffect } from 'react'
import { cn, rmEmptyKeys } from '@components/helper'
import { useRelativeLink } from '@commerce/product/use-relative-link'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import s from './index.module.css'
import ProgressBar from '../ProgressBar'

const StayConnected = ({ data, id, pageHandle, abtest }) => {
  const { setRelativeLink } = useRelativeLink()

  const [isMobile, setIsMobile] = useState(false)

  const resizeWindow = () => {
    const offsetWidth = document?.querySelector('body')?.offsetWidth || 0
    if (offsetWidth <= 768) return setIsMobile(true)
    setIsMobile(false)
  }

  useEffect(() => {
    resizeWindow()
    window.addEventListener('resize', resizeWindow)

    return () => {
      window.removeEventListener('resize', resizeWindow)
    }
  }, [])

  const [realIndex, setRealIndex] = useState(0)
  const [swiperRef, setSwiperRef] = useState(null)

  const settings = {
    speed: 10000,
    loop: true,
    slidesPerView: 4.5,
    centeredSlides: true,
    autoplay: data?.autoplay || {
      delay: 0,
      disableOnInteraction: true,
    },
    modules: [Autoplay],
    breakpoints: {
      0: {
        speed: 400,
        spaceBetween: 8,
        slidesPerView: 1.7,
      },
      768: {
        speed: 400,
        slidesPerView: 2.5,
        spaceBetween: 8,
      },
      769: {
        speed: 10000,
        spaceBetween: 12,
        slidesPerView: 3.5,
      },
      1024: {
        speed: 10000,
        spaceBetween: 16,
        slidesPerView: 4.5,
      },
    },
    ...(isMobile && {
      autoplay: data?.mobAutoplay || {
        delay: 6000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
      },
    }),
  }

  return (
    <div
      id={id}
      className={cn(
        'relative overflow-hidden py-[140px] leading-[1.3] md:py-[65px] md:pb-[90px] md:pr-0',
        {
          ['pt-0 md:pt-0']: data?.delPaddingTop,
          ['pb-0 md:pb-0']: data?.delPaddingBottom,
        }
      )}
      style={rmEmptyKeys({
        backgroundColor: data?.bgColor,
        paddingBottom: data?.paddingBottom,
      })}
    >
      <div className="layer">
        <div className="content !max-w-[1410px]">
          {data?.title && (
            <Text
              html={data?.title}
              className={cn(
                'mb-[18px] text-[56px] font-[700] uppercase leading-[1.2] text-[#000] md:mb-[10px] md:text-[32px]',
                {
                  '!text-center': abtest,
                }
              )}
            />
          )}

          {data?.subtitle && (
            <a
              className={cn(s.learnMore, {
                '!text-center !block': abtest,
              })}
              href={`${setRelativeLink({ link: data?.link })}?ref=${
                data?.ref || pageHandle
              }`}
              dangerouslySetInnerHTML={{
                __html: data?.subtitle,
              }}
            />
          )}
        </div>
      </div>

      <Swiper
        {...settings}
        className={s.swiperBox}
        onSwiper={setSwiperRef}
        onSlideChange={(swiper) => setRealIndex(swiper.realIndex)}
      >
        {data?.list?.map((item, index) => (
          <SwiperSlide key={index} className="relative">
            <Row
              item={item}
              data={data}
              key={index}
              index={index}
              isMobile={isMobile}
              realIndex={realIndex}
              pageHandle={pageHandle}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      <style jsx global>
        {`
          #${id} .${s.swiperBox} .swiper-wrapper {
            transition-timing-function: linear;
          }
        `}
      </style>
    </div>
  )
}

export default StayConnected

const Row = ({ item, data, realIndex, index, isMobile }) => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    let timeoutId
    if (realIndex == index) {
      setIsActive(true)
      timeoutId = setTimeout(() => {
        setIsActive(false)
      }, 4000)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [index, realIndex])

  const integerPart = Math.floor(item?.start) || 0
  const decimalPart = item.start - integerPart

  return (
    <div
      className={cn(s.hoverBox, {
        [s.mobActive]: isMobile && isActive,
      })}
    >
      <Picture
        source={`${item?.img}, ${item?.mobImg || item?.img} 768`}
        className={cn(
          'aspect-h-[420] aspect-w-[416] w-full md:aspect-h-[200] md:aspect-w-[180]'
        )}
      />
      <div className={s.contentWrap}>
        <div className={s.textWrap}>
          {item?.start && item?.start >= 0 && (
            <div className="mb-[10px] flex justify-center">
              {Array(integerPart)
                .fill()
                .map((item, index) => (
                  <Picture
                    key={index}
                    source={`${`${
                      data?.complete ||
                      'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Group_1312319019.png?v=1720669644'
                    }`} 768`}
                    className={cn('h-[24px] w-[24px]')}
                  />
                ))}

              {decimalPart > 0 && (
                <Picture
                  key={integerPart}
                  source={`${`${
                    data?.incomplete ||
                    'https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Group_1312319018.png?v=1720669643'
                  }`} 768`}
                  className={cn('h-[24px] w-[24px]')} // 假设小数部分生成一个缩小版的 Picture
                />
              )}
            </div>
          )}

          {item?.title && (
            <Text
              html={item.title}
              className="mt-[5px] text-[24px] font-[600] md:text-[18px]"
            />
          )}

          {item?.desc && (
            <Text
              html={item.desc}
              className="mt-[5px] text-[16px] font-[500] md:text-[14px]"
            />
          )}

          {item.name && (
            <Text
              html={item.name}
              className="mt-[33px] text-[16px] font-medium md:mt-[15px]"
            />
          )}
        </div>
      </div>
      <ProgressBar s={{ progressBarBox: s.progressBarBox }} />
    </div>
  )
}
