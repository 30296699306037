import { Picture, Text } from '@components/ui'
import SectionTitle from '../SectionTitle'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import { Arrow } from '../Arrow'
import { useState } from 'react'
import BannerLink from '@components/common/BannerLink'
import { useRouter } from 'next/router'
import s from './index.module.css'
import aniStyle from '../index.module.css'
import { appendQueryParams } from 'templates/Pages/HomePps'

const Card = ({ jItem, data }) => {
  return (
    <div
      className={cn(
        'relative w-full cursor-pointer overflow-hidden rounded-[10px]',
        aniStyle?.card_parent,
        aniStyle?.card_parent_ani
      )}
    >
      <Picture
        source={`${jItem?.img}, ${jItem?.mbImg || jItem?.img} 768`}
        className={cn(
          'aspect-w-[628] aspect-h-[340] w-full',
          aniStyle?.card_parent_img
        )}
      ></Picture>
      <div className="aspect-w-[628] aspect-h-[198] bg-[#fff] md:aspect-w-[342] md:aspect-h-[164]">
        <div className="box-border flex h-full flex-col justify-center px-[32px] md:px-[16px] md-xl:px-[24px]">
          <Text
            className="text-[24px] font-semibold leading-[28px] text-[#000] l:text-[16px] l:leading-[22px] l-xl:text-[20px] l-xl:leading-[24px]"
            html={jItem?.title}
          ></Text>
          <Text
            className="mt-[16px] text-[16px] leading-[22px] text-[#6E6E73] l:line-clamp-3 xl:min-h-[30px] xl:text-[12px] xl:leading-[16px] min-md:min-h-[42px] md-xl:mt-[4px]"
            html={jItem?.description}
          ></Text>
          {data?.learnMoreTxt && (
            <div className="mt-[16px] inline-flex items-center md-xl:mt-[4px]">
              <Text
                className={cn(
                  'text-[20px] font-medium text-[#06A9E0] l:text-[16px]',
                  aniStyle?.card_parent_link
                )}
                html={data?.learnMoreTxt}
              ></Text>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M10.5 8L14.5 12L10.5 16"
                  stroke="#06A9E0"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          )}
        </div>
      </div>
      <BannerLink
        to={appendQueryParams(jItem?.learnMoreHref, 'ref=homePageBanner_pps')}
      ></BannerLink>
    </div>
  )
}

function RightsArea({ data, pageHandle, id }) {
  // start_ai_generated
  const [isStart, setStart] = useState(true)
  const [isEnd, setEnd] = useState(false)
  const { locale } = useRouter()
  // end_ai_generated
  return (
    <>
      <div className="layer overflow-x-hidden bg-[#F5F5F7] py-[96px] md:py-[72px] md:pr-0">
        <div className="content md:overflow-hidden" id={id}>
          {/* start_ai_generated */}
          <SectionTitle txt={data?.title}></SectionTitle>
          {/* end_ai_generated */}
          {data?.list && (
            <div className="mt-[48px] w-screen md:mt-[32px]">
              <div className={cn(s.custom_max_w, 'relative')}>
                <Swiper
                  className="!overflow-visible"
                  navigation={{
                    nextEl: '.rights-custom-swiper-button-next',
                    prevEl: '.rights-custom-swiper-button-prev',
                  }}
                  modules={[Navigation]}
                  onProgress={(swiper) => {
                    setStart(swiper.isBeginning)
                    setEnd(swiper.isEnd)
                  }}
                  breakpoints={{
                    0: {
                      spaceBetween: '10px',
                      slidesPerView: 1.1,
                    },
                    768: {
                      spaceBetween: '12px',
                      slidesPerView: 2,
                    },
                    1024: {
                      spaceBetween: '16px',
                      slidesPerView: 2,
                    },
                    1440: {
                      spaceBetween: '24px',
                      slidesPerView: 2,
                    },
                  }}
                >
                  {/* start_ai_generated */}
                  {data?.list?.map((jItem, jIndex) => {
                    return (
                      <SwiperSlide key={jIndex + jItem?.title}>
                        <Card
                          locale={locale}
                          jItem={jItem}
                          pageHandle={pageHandle}
                          data={data}
                        ></Card>
                      </SwiperSlide>
                    )
                  })}
                  {/* end_ai_generated */}
                </Swiper>
                {/* start_ai_generated */}
                <Arrow
                  className={data?.list?.length <= 2 ? 'min-md:hidden' : ''}
                  isStart={isStart}
                  isEnd={isEnd}
                  prevClass="rights-custom-swiper-button-prev"
                  nextClass="rights-custom-swiper-button-next"
                ></Arrow>
                {/* end_ai_generated */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default RightsArea
