import cn from 'classnames'
import s from './index.module.css'
function Tab({ tabs = [], activeTab, setActiveTab }) {
  return (
    tabs.length > 1 && (
      <div className="mt-[32px] w-full overflow-x-auto">
        <div className="flex items-center justify-center gap-[24px] overflow-x-auto md:justify-start md:pr-[24px]">
          {tabs.map((tab) => {
            return (
              <button
                key={tab?.tabId}
                className={cn(
                  'h-[35px] rounded-[30px] px-[24px] text-[16px] font-medium md:flex-shrink-0 md:px-[12px] md:text-[14px]',
                  `${
                    activeTab === tab?.tabId
                      ? s?.lightBg
                      : 'bg-white text-[#333]'
                  }`
                )}
                onClick={() => {
                  setActiveTab(tab?.tabId)
                }}
              >
                {tab?.tabName}
              </button>
            )
          })}
        </div>
      </div>
    )
  )
}

export default Tab
