import { Picture } from '@components/ui'
import SectionTitle from '@components/layout/SectionTitle'
import styles from './News.module.css'
import cn from 'classnames'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import { useRef } from 'react'
import { useRelativeLink } from '@commerce/product/use-relative-link'

const Advantage = ({ data, pageType }) => {
  const { setRelativeLink } = useRelativeLink()
  const refAdvantage = useRef(null)

  let itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: {
      x: '-120%',
      transition: { duration: 0.3 },
    },
  }
  const { scrollYProgress } = useScroll({
    target: refAdvantage,
    offset: ['start end', 'end center'],
  })
  return (
    <div className={cn(styles.root, 'overflow-hidden')}>
      <SectionTitle
        subTitle={data?.subTitle}
        title={data?.title}
        className={styles.title_box}
        cns={{
          subTitle: styles.subTitle,
        }}
        jumpOver={data?.jumpOver}
        blogLink={data?.blogLink}
      />
      <section
        className={cn(
          styles.news_layer,
          { [styles.news_layer_padding]: data.haspadding },
          'layer'
        )}
      >
        <motion.div layout className={cn(styles.news_content, 'content')}>
          {data.content &&
            data.content.map((v, i) => (
              <motion.div
                layout
                key={`news-${i}`}
                ref={refAdvantage}
                className={cn(styles.news_box, {
                  [styles.news_onlyOne]: v.onlyOne,
                  [styles.news_onlyOne_left]: v?.imgLeft,
                })}
                initial="hidden"
                whileInView="show"
                viewport={{ once: true, amount: 0.6 }}
                variants={{
                  show: {
                    transition: {
                      duration: 0.5,
                      staggerChildren: 0.05,
                    },
                  },
                  hidden: {
                    transition: {
                      duration: 0.5,
                    },
                  },
                }}
              >
                <motion.div
                  layout
                  className={cn(styles.news_flex, {
                    [styles.news_flex_onlyOne]: v.onlyOne,
                    [styles.news_flex_onlyOne_left]: v?.imgLeft,
                  })}
                >
                  <motion.div
                    layout
                    className={cn(styles.news_textAll, {
                      [styles.news_textAllOnlyOne]: v.onlyOne,
                      [styles.news_textAllOnlyOne_left]: v?.imgLeft,
                    })}
                  >
                    <motion.div
                      layout
                      className={cn(styles.news_title_box, {
                        [styles.news_titleOnlyOne_box]: v.onlyOne,
                      })}
                    >
                      <motion.div className="md_layer_grid">
                        <motion.div className="md_content_grid">
                          <motion.div
                            className={cn(
                              styles.news_title_grid,
                              'md_content_grid',
                              {
                                [styles.news_titleOnlyOne_grid]: v.onlyOne,
                              }
                            )}
                          >
                            <motion.p
                              variants={itemVariants}
                              layout
                              className={styles.news_subTitle}
                              dangerouslySetInnerHTML={{
                                __html: v.subTitle,
                              }}
                            />
                            {v?.title &&
                              v?.title.map((item, index) => (
                                <motion.p
                                  layout
                                  key={`newstitle-${index}`}
                                  variants={itemVariants}
                                  className={cn(styles.news_title, {
                                    [styles.news_titleOnlyOne]: v.onlyOne,
                                  })}
                                  dangerouslySetInnerHTML={{
                                    __html: item?.title,
                                  }}
                                />
                              ))}
                          </motion.div>
                          {v?.text && (
                            <motion.p
                              variants={itemVariants}
                              layout
                              className={styles.news_text}
                              dangerouslySetInnerHTML={{
                                __html: v?.text,
                              }}
                            />
                          )}
                          <motion.p
                            variants={itemVariants}
                            layout
                            className={cn(styles.news_a_box, {
                              [styles.news_a_box_onlyOne]: v.onlyOne,
                            })}
                          >
                            <motion.a
                              className={styles.news_a}
                              layout
                              href={`${setRelativeLink({
                                link: v?.link,
                              })}?ref=homePageBanner${
                                pageType === 'powerStation' ||
                                pageType === 'hes'
                                  ? '_pps'
                                  : ''
                              }`}
                              target="_self"
                              dangerouslySetInnerHTML={{
                                __html: v?.learnMore,
                              }}
                            />
                          </motion.p>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                  <motion.div className="flex-1 cursor-pointer overflow-hidden">
                    <Picture
                      source={`${v?.img},${v?.img_mob} 768`}
                      className={cn(
                        styles.news_img,
                        { ['aspect-w-[1088] aspect-h-[500]']: v.onlyOne },
                        { ['aspect-w-[534] aspect-h-[300]']: !v.onlyOne },
                        'md:aspect-w-[320] ',
                        'md:aspect-h-[300]'
                      )}
                    />
                  </motion.div>
                </motion.div>
              </motion.div>
            ))}
        </motion.div>
      </section>
    </div>
  )
}
export default Advantage
