import SectionTitle from '../SectionTitle'
import { Picture, Text } from '@components/ui'

const Card = ({ item, last }) => {
  return (
    <div className="text-center md:w-full min-md:col-span-4">
      <div className="relative w-full">
        <Picture
          className="aspect-w-[411] aspect-h-[300] mx-auto w-[60%]"
          imgClassName="w-full h-full object-cover"
          source={`${item?.img}, ${item?.mbImg || item?.img} 768`}
        ></Picture>
        {!last && (
          <Picture
            className="absolute right-0 top-[50%] w-[112px] translate-x-[50%] translate-y-[-50%] md:hidden l:w-[54px]"
            source="https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Vector_1622_a0eb95b4-3fa6-4711-96a8-46de52d8a748.png?v=1707038758"
          ></Picture>
        )}
      </div>

      {item?.title && (
        <Text
          className="mt-[32px] box-border w-full px-[5%] text-[40px] font-semibold leading-[44px] text-black l:mt-[24px] l:text-[20px] l:leading-[24px] l-xl:text-[24px] l-xl:leading-[28px]"
          html={item?.title}
        ></Text>
      )}
      {item?.subtitle && (
        <Text
          className="mt-[16px] box-border w-full px-[2%] text-[17px] font-medium leading-[21px] text-[#6E6E73] l:mt-[8px] l:text-[14px] l:leading-[18px]"
          html={item?.subtitle}
        ></Text>
      )}
    </div>
  )
}

function SoFunktioniert({ data, id }) {
  return (
    <div className="layer bg-[#F5F5F7] pt-[120px] md:pt-[72px]">
      <div className="content" id={id}>
        {data?.title && <SectionTitle txt={data?.title}></SectionTitle>}
        {data?.list && (
          <div className="mt-[48px] gap-[32px] md:mt-[32px] md:flex md:flex-wrap  md:gap-[0px] min-md:grid min-md:grid-cols-12 l-xl:gap-[24px] md-l:gap-[16px]">
            {data?.list.map((item, index) => {
              const isLast = index === data?.list?.length - 1
              return (
                <>
                  <Card
                    last={isLast}
                    key={index + item?.title}
                    item={item}
                  ></Card>
                  {!isLast && (
                    <Picture
                      source="https://cdn.shopify.com/s/files/1/0517/6767/3016/files/Vector_1623_f0433a83-0467-4c6b-b4d7-64fc6ea8f3e7.png?v=1707039507"
                      className="mx-auto my-[16px] w-[3px] min-md:hidden"
                    ></Picture>
                  )}
                </>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
export default SoFunktioniert
