import { useState } from 'react'
import { Picture, Text } from '@components/ui'
import { cn, rmEmptyKeys } from '@components/helper'

import s from './index.module.css'

const Index = ({ id, data }) => {
  const [current, setCurrent] = useState(0)
  return (
    <section
      id={id}
      className={cn(
        'layer relative overflow-hidden bg-[#fff] py-[140px] leading-[1.2] md:py-[65px] md:pb-[90px]',
        {
          ['pt-0 md:pt-0']: data?.delPaddingTop,
          ['pb-0 md:pb-0']: data?.delPaddingBottom,
        }
      )}
      style={rmEmptyKeys({
        background: data?.bgColor,
      })}
    >
      <div className="content !max-w-[1410px]">
        <ul className="flex flex-wrap items-center justify-center gap-x-[40px] l:justify-start l:gap-0">
          {data?.list?.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                if (current != index && item.text) {
                  setCurrent(index)
                }
              }}
              className={cn(
                { ['cursor-pointer']: item?.text },
                'l:flex l:w-[50%] l:justify-center'
              )}
            >
              <div className="min-w-[168px] md:min-w-[108px] md:max-w-[108px]">
                <Picture
                  source={`${item?.img}, ${item?.mobImg || item?.img} 768`}
                  className={cn(
                    'aspect-h-[112] aspect-w-[168] w-full md:aspect-h-[72] md:aspect-w-[108]'
                  )}
                />
              </div>
            </li>
          ))}
        </ul>
        {data?.list[current]?.text && (
          <div className={s.descWrap}>
            <Text html={data?.list[current]?.text} className={s.desc} />
          </div>
        )}
      </div>
    </section>
  )
}

export default Index
