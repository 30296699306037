import cn from 'classnames'
import { useState, useRef, Fragment, useCallback, useEffect } from 'react'
import { useRouter } from 'next/router'
import { Text, Picture } from '@components/ui'
import { motion, LayoutGroup, useScroll, useTransform } from 'framer-motion'
import Button from '@components/control/Button'
import s from './HotSpots.module.css'
import usePrice, { formatPrice } from '@commerce/product/use-price'
import getProductCouponsBySkus from '@lib/apis/get-product-coupons-by-skus'
import SolixButton from '@components/control/SolixButton'
import { useRelativeLink } from '@commerce/product/use-relative-link'
const HotSpot = ({
  lang = {},
  index,
  pageType,
  mobIsSwiper,
  relatedProducts,
  metafields,
  indexList,
}) => {
  const { locale } = useRouter()
  const refSpot = useRef(null)
  const [discountData, setDiscountData] = useState({})
  const { setRelativeLink } = useRelativeLink()
  const { productInfos, shopCommon } = metafields
  let product = {}
  relatedProducts?.map((v, index) => {
    if (v.origin.handle == lang.handle) {
      product = v.result
    }
  })
  const couponCallback = useCallback(async () => {
    const skus = []
    skus.push(product?.variants?.[0]?.sku)
    const result = await getProductCouponsBySkus(locale, skus)
    setDiscountData(result)
  }, [])
  useEffect(() => {
    couponCallback()
  }, [couponCallback])
  const { price, basePrice } = usePrice(
    product?.variants
      ? {
          amount: product?.variants[0]?.price,
          baseAmount: Number(
            discountData[product?.variants?.[0]?.sku]?.[0]?.variant_price4wscode
          ),
          currencyCode: product.price.currencyCode,
        }
      : {}
  )
  const { scrollYProgress } = useScroll({
    target: refSpot,
    offset: ['1 1', '2 1'],
  })

  const useParallax = (value, distance) => {
    return useTransform(value, [0, 1], [0, distance])
  }

  const itemVariants = {
    show: {
      x: 0,
      transition: { duration: 0.3 },
    },
    hidden: { x: '-100%', transition: { duration: 0.3 } },
  }

  const link = `${setRelativeLink({ link: lang?.link })}?ref=${
    pageType == 'charger' ? '' : 'pps_'
  }productBanner${indexList}_${index + 1}`

  return (
    <div
      className={cn(s.HotSpot, {
        [s.leftImg]: index % 2 == 0,
        [s.HotSpotMobIsSwiper]: mobIsSwiper,
        ['!cursor-auto']: !lang?.viewProductBtn,
      })}
      onClick={() => {
        !lang.buyBtn && (window.location.href = link)
      }}
      ref={refSpot}
    >
      <div className={cn(s.textBox_all, 'md_layer_grid')}>
        <div className="md_content_grid h-full">
          <motion.div
            className={s.textBox}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, amount: 0.6 }}
            variants={{
              show: {
                transition: {
                  duration: 0.3,
                  staggerChildren: 0.05,
                },
              },
              hidden: {
                transition: {
                  duration: 0.3,
                },
              },
            }}
          >
            <div
              className={cn(s.contentBox, 'flex', {
                ['md:hidden ']: mobIsSwiper,
              })}
            >
              <div className={s.text}>
                {lang?.subTitle && (
                  <div className={s.spotSubTitle}>
                    <motion.div
                      layout
                      variants={itemVariants}
                      className={s.wrap}
                      dangerouslySetInnerHTML={{
                        __html: lang?.subTitle,
                      }}
                    ></motion.div>
                  </div>
                )}

                <div
                  className={cn(s.spotTitle, {
                    [s.spotTitleHasHeight]: mobIsSwiper,
                  })}
                >
                  {lang?.title &&
                    lang?.title.map((v, i) => (
                      <Fragment key={`spot-${index}-${i}`}>
                        <motion.h3
                          layout
                          variants={itemVariants}
                          className={cn(s.wrap, s.wrap_title)}
                          dangerouslySetInnerHTML={{
                            __html: v,
                          }}
                        ></motion.h3>
                        {i < lang?.title?.length - 1 && (
                          <span className="wrap pc" />
                        )}
                      </Fragment>
                    ))}
                </div>
                <div
                  className={cn(s.description, {
                    [s.spotTitleHasHeight]: mobIsSwiper,
                  })}
                >
                  <motion.div
                    layout
                    variants={itemVariants}
                    className={cn(s.wrap, s.wrap_text)}
                    dangerouslySetInnerHTML={{
                      __html: lang?.text,
                    }}
                  ></motion.div>
                  <motion.div
                    layout
                    variants={itemVariants}
                    className={s.wrap_tips}
                    dangerouslySetInnerHTML={{
                      __html: lang.tips,
                    }}
                  ></motion.div>
                </div>
              </div>
              <motion.div
                layout
                variants={itemVariants}
                className={s.actionBox}
              >
                {lang.viewProductBtn && (
                  <>
                    {pageType === 'powerStation' || pageType === 'hes' ? (
                      <>
                        <SolixButton
                          className={cn('mr-3 ', {
                            [s.btn]: !lang.buyBtn,
                            ['basis-2/3']: lang.buyBtn,
                          })}
                        >
                          <a
                            href={link}
                            target="_self"
                            className={cn('block')}
                            dangerouslySetInnerHTML={{
                              __html:
                                lang.viewProductBtn +
                                `<span class="hidden">${lang?.title}</span>`,
                            }}
                          ></a>
                        </SolixButton>
                      </>
                    ) : (
                      <>
                        <Button
                          className={cn('mr-3 ', {
                            [s.btn]: !lang.buyBtn,
                            ['basis-2/3']: lang.buyBtn,
                          })}
                        >
                          <a
                            href={link}
                            target="_self"
                            className={cn('block')}
                            dangerouslySetInnerHTML={{
                              __html:
                                lang.viewProductBtn +
                                `<span class="hidden">${lang?.title}</span>`,
                            }}
                          ></a>
                        </Button>
                      </>
                    )}
                  </>
                )}
                {lang.buyBtn && (
                  <Button className="basis-1/3" border small theme="light">
                    {/* {lang.buyBtn} */}
                    <a
                      href={link}
                      target="_self"
                      className="block"
                      dangerouslySetInnerHTML={{
                        __html: lang.buyBtn,
                      }}
                    ></a>
                  </Button>
                )}
              </motion.div>
            </div>
            {mobIsSwiper && (
              <div className={cn(s.contentBox, 'hidden', 'md:flex')}>
                <div className={s.text}>
                  {lang?.subTitle && (
                    <div className={s.spotSubTitle}>
                      <motion.div
                        layout
                        className={s.wrap}
                        dangerouslySetInnerHTML={{
                          __html: lang?.subTitle,
                        }}
                      ></motion.div>
                    </div>
                  )}
                  <div
                    className={cn(s.spotTitle, {
                      [s.spotTitleHasHeight]: mobIsSwiper,
                    })}
                  >
                    {lang?.title &&
                      lang?.title.map((v, i) => (
                        <Fragment key={`spot-${index}-${i}`}>
                          <motion.h3
                            layout
                            className={cn(s.wrap, s.wrap_title)}
                          >
                            <motion.span
                              dangerouslySetInnerHTML={{
                                __html: v,
                              }}
                            ></motion.span>
                            {i < lang?.title?.length - 1 && (
                              <span className="wrap pc" />
                            )}
                          </motion.h3>
                        </Fragment>
                      ))}
                  </div>
                  <div
                    className={cn(s.description, {
                      [s.spotTitleHasHeight]: mobIsSwiper,
                    })}
                  >
                    <motion.div
                      layout
                      className={cn(s.wrap, s.wrap_text)}
                      dangerouslySetInnerHTML={{
                        __html: lang?.text,
                      }}
                    ></motion.div>
                    <motion.div
                      layout
                      className={s.wrap_tips}
                      dangerouslySetInnerHTML={{
                        __html: lang.tips,
                      }}
                    ></motion.div>
                  </div>
                </div>
                <motion.div layout className={s.actionBox}>
                  {lang.viewProductBtn && (
                    <>
                      {pageType === 'powerStation' || pageType === 'hes' ? (
                        <>
                          <SolixButton
                            className={cn('mr-3 ', {
                              [s.btn]: !lang.buyBtn,
                              ['basis-2/3']: lang.buyBtn,
                            })}
                          >
                            <a
                              href={link}
                              target="_self"
                              className={cn('block')}
                              dangerouslySetInnerHTML={{
                                __html: lang.viewProductBtn,
                              }}
                            ></a>
                          </SolixButton>
                        </>
                      ) : (
                        <>
                          <Button
                            className={cn('mr-3 ', {
                              [s.btn]: !lang.buyBtn,
                              ['basis-2/3']: lang.buyBtn,
                            })}
                          >
                            <a
                              href={link}
                              target="_self"
                              className={cn('block')}
                              dangerouslySetInnerHTML={{
                                __html: lang.viewProductBtn,
                              }}
                            ></a>
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  {lang.buyBtn && (
                    <Button className="basis-1/3" border small theme="light">
                      {/* {lang.buyBtn} */}
                      <a
                        href={link}
                        target="_self"
                        className="block"
                        dangerouslySetInnerHTML={{
                          __html: lang.buyBtn,
                        }}
                      ></a>
                    </Button>
                  )}
                </motion.div>
              </div>
            )}
          </motion.div>
        </div>
      </div>
      <div className={s.imgBox}>
        <motion.div
          initial={{
            scale: 1,
          }}
          whileHover={{
            scale: 1.05,
          }}
          className="h-full"
          transition={{ duration: 0.3, ease: 'easeInOut' }}
        >
          <Picture
            source={`${lang?.bgUrl},${lang.bgUril_mob} 768`}
            alt=""
            loading="eager"
            className={cn(
              s.HotSpot_img,
              'aspect-w-[740]',
              ' aspect-h-[370]',
              'md:aspect-w-[342] ',
              'md:aspect-h-[200]',
              {
                ['md:aspect-w-[283] md:aspect-h-[165]']: mobIsSwiper,
              }
            )}
          />
        </motion.div>
      </div>
    </div>
  )
}

export default HotSpot
